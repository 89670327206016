import Actions from "../actions";
import { produce } from "immer";

interface State {
  departments:{
    totalCount:number;
    departmentDetails:[]
  };
  departmentSelectList: [];
  selectedDepartmentId:number;
  selectedDepartmentStatus:boolean;
  departmentDetails:{}
}

const initialState: State = {
  departments: {
    totalCount:0,
    departmentDetails:[]
  },
  departmentSelectList: [],
  selectedDepartmentId:0,
  selectedDepartmentStatus:false,
  departmentDetails:{}
};

const department = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_DEPARTMENT_LIST: {
      draft.departments = payload?.departments;
      break;
    }

    case Actions.GET_DEPARTMENT_SELECT_LIST: {
      draft.departmentSelectList = payload?.departments;
      break;
    }

    case Actions.SELECTED_DEPARTMENT_ID: {
      draft.selectedDepartmentId = payload?.selectedDepartmentId;
      break;
    }

    case Actions.SELECTED_DEPARTMENT_STATUS: {
      draft.selectedDepartmentStatus = payload?.selectedDepartmentStatus;
      break;
    }

    case Actions.GET_DEPARTMENT_DETAIL: {
      draft.departmentDetails = payload?.departmentDetails;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default department;
