import React from 'react'
import "./Tooltip.scss"

type Props = {
    children:React.ReactNode;
    title:string;
} & React.HTMLAttributes<HTMLDivElement>

const Tooltip = ({children, title, ...props}: Props) => {
  return (
    <div className="tooltip-wrapper" {...props}>
        <div data-position="left" data-tooltip={title} className="tooltip flex_box flex_box--items-center flex_box--justify-center flex_box--text-center">
        {children}
        </div>
    </div>
  )
}

export default Tooltip