import { Location, NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./projectSummaryApis";
import { Dispatch, SetStateAction } from "react";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import { createService } from "../createService/createService";

/* GET PROJECT SUMMARY */

export type GetProjectSummaryTypes = {
  isActive: any;
  pageNumber: number;
  pageSize: number;
  orderBy: boolean;
  searchString: string;
};

export const getProjectSummaryAction =
  (
    dataToBeSent: GetProjectSummaryTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectSummarys(dataToBeSent)
 response &&  dispatch(
      Actions.createAction(Actions.GET_PROJECT_SUMMARY_LIST, {
        projectSummarys: response?.data,
      })
    );
    return response;
  };

/* GET PROJECT SUMMARY */

/* ADD PROJECT SUMMARY */

export type AddProjectSummaryTypes = {
  id: number;
  loginUserId: number;
  customerId: number;
  projectNo: string;
  projectName: string;
  serviceOrderNo: string;
  po: string;
  addressLine1: string;
  addressLine2: string;
  country: number;
  state: number;
  city: number;
  zipCode: string;
  status: string;
  amount: number;
  startDate: string;
  endDate: string;
  typeofWork: string;
  workDetails: string;
};

export const addProjectSummaryAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    setLoading: Dispatch<SetStateAction<boolean>>,
    dataToBeSent: AddProjectSummaryTypes,
    location:Location
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addProjectSummarysDetails(dataToBeSent)
    if(response){
      dispatch(Actions.createAction(Actions.ADD_PROJECT_SUMMARY_DETAILS, {projectSummaryDetail: response?.data}));
      if(location.pathname.includes("sales")){
        navigate(AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_LIST);
      }
      else {
        navigate(AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST);
      }
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };
/* ADD PROJECT SUMMARY */

/* GET PROJECT ID */
export const getProjectIdAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectId()
    response &&  dispatch(
      Actions.createAction(Actions.ADD_PROJECT_SUMMARY_DETAILS, {
        projectId: response?.data,
      })
    );
    return response;
  };
/* GET PROJECT ID */

/* GET CUSTOMER LIST */
export const getCustomerListAction =
  (
    dataToBeSent: { RegionId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomerSelectList(dataToBeSent)
    response &&  dispatch(
      Actions.createAction(Actions.GET_CUSTOMER_SELECT_LIST, {
        RegionId: response?.data,
      })
    );
    return response;
  };

/* GET CUSTOMER LIST */

/* GET STATUS LIST */
export const getStatusListAction =
  ( ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getStatusSelectList()
    response &&  dispatch(
      Actions.createAction(Actions.GET_OPERATION_STATUS_SELECT_LIST, {
        statusSelectList: response?.data,
      })
    );
    return response;
  };

/* GET STATUS LIST */

/* GET PROJECT SUMMARY */
export const getProjectSummaryDetailAction =
  (
    dataToBeSent: { projectId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectSummaryDetail(dataToBeSent)
    response &&  dispatch(
      Actions.createAction(Actions.GET_PROJECT_SUMMARY_DETAIL, {
        projectId: response?.data,
      })
    );
    return response;
  };
/* GET PROJECT SUMMARY */

/* GET PROJECT LIST */
export const getProjectListAction =
  (
    dataToBeSent: { CustomerId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectSelectLists(dataToBeSent)
    response &&  dispatch(
      Actions.createAction(Actions.GET_PROJECT_SELECT_LIST, {
        CustomerId: response?.data,
      })
    );
    return response;
  };
/* GET PROJECT LIST */

/* ACTIVE IN ACTIVE PROJECT SUMMARY STATUS */
export const activeInactiveProjectSummaryAction =
  (
    dataToBeSent: { projectId: number; isActive: boolean }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.activeInactiveProjectSummary(dataToBeSent)
    dispatch(
      Actions.createAction(
        Actions.UPDATE_PROJECT_SUMMARY_ACTIVE_INACTIVE_STATUS,
        { projectSummaryActiveInActiveStatus: response?.data }
      )
    );
    return response;
  };
/* ACTIVE IN ACTIVE PROJECT SUMMARY STATUS */

/* UPDATE PROJECT SUMMARY STATUS */
export const updateProjectSummaryStatusAction =
  (
    dataToBeSent: { projectId: number; status: string }
  ) =>

async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response:any = await requestFromServer.updateProjectSummaryStatus(dataToBeSent)
  dispatch(Actions.createAction(Actions.UPDATE_PROJECT_SUMMARY_STATUS,{projectSummaryStatus:response?.data}));
  return response
};

/* UPDATE PROJECT SUMMARY STATUS */


