import Actions from "../actions";
import { produce } from "immer";

interface State {
  user : {
    isAuthenticated: boolean;
  }
}

const initialState: State = {
  user : {
    isAuthenticated: false,
  }
};

const auth = produce((draft: State, action: any) => {
  switch (action.type) {
    case Actions.USER_LOGIN: {
      draft.user = {
        isAuthenticated:true
      };
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default auth;
