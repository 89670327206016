export const appOperationsMenuUtils = () => {
  const menuGroup = [];

  /* menuItemOperationsDashboard */
  const menuItemOperationsDashboard = {
    id: "operationsDashboard",
    title: "Dashboard",
    url: "/operations/dashboard",
  };
  menuGroup.push(menuItemOperationsDashboard);
  /* menuItemOperationsDashboard */
  /* menuItemOperationsCustomerManagement */
  const menuItemOperationsCustomerManagement = {
    id: "customerManagement",
    title: "Customers",
    url: "/operations/customerManagement",
  };
  menuGroup.push(menuItemOperationsCustomerManagement);
  /* menuItemOperationsCustomerManagement */
  /* menuItemOperationsProjectSummary */
  const menuItemOperationsProjectSummary = {
    id: "projectSummary",
    title: "Project Summary",
    url: "/operations/projectSummary",
  };
  menuGroup.push(menuItemOperationsProjectSummary);
  /* menuItemOperationsProjectSummary */

  /* menuItemOperationsWorkFlow */
  const menuItemOperationsWorkFlow = {
    id: "workFlow",
    title: "Work Flow",
    url: "/operations/workFlow",
  };
  menuGroup.push(menuItemOperationsWorkFlow);
  /* menuItemOperationsWorkFlow */


  return menuGroup;
};
