import * as authActions from './authActions'
import * as departmentActions from './departmentActions'
import * as regionActions from './regionActions'
import * as employeeManagementActions from './employeeManagementActions'
import * as documentManagementActions from './documentManagementActions'
import * as loadingScreenActions from './loadingScreenActions'
import * as commonActions from './commonActions'
import * as createAction from './createAction'
import * as projectSummary from "./projectSummaryActions"
import * as workFlowActions  from './workFlowActions'
import * as attendanceManagementActions from "./attendanceManagementActions"
import * as customerManagementActions from "./customerManagementActions";
import * as leaveManagementActions from "./leaveManagementActions";
import * as quotesActions from "./quotesActions";
import * as driverCertificationActions from "./driverCertificationActions";
import * as campaingsAction from "./campaignActions"
import * as agentActions from "./agentActions"
import * as leadActions from "./leadActions"
import * as fleetManagementActions from './fleetManagementActions'
import * as crmDashboardActions from "./crmDashboardActions"
import * as salesRepActions from "./salesRepActions"
import * as vendorsActions from "./vendorsActions"
import * as partnersActions from "./partnersActions"
import * as financeActions from "./financeActions"
import * as hrDashboardActions from "./hrDashboardActions"

//Add all the actions here in actions object const to mirror actions
const keyMirror = (list: any) => {
  let newList: any = {};
  Object.keys(list).map((element) => {
    const key = String(element);
    newList[key] = element;
  });
  return newList;
};

const actions = keyMirror({
  ...authActions,
  ...loadingScreenActions,
  ...departmentActions,
  ...regionActions,
  ...projectSummary,
  ...workFlowActions,
  ...customerManagementActions,
  ...employeeManagementActions,
  ...documentManagementActions,
  ...commonActions,
  ...attendanceManagementActions,
  ...leaveManagementActions,
  ...quotesActions,
  ...driverCertificationActions,
  ...campaingsAction,
  ...agentActions,
  ...leadActions,
  ...fleetManagementActions,
  ...crmDashboardActions,
  ...salesRepActions,
  ...vendorsActions,
  ...partnersActions,
  ...financeActions,
  ...hrDashboardActions
})

//Exporting all the actions from folder
export default {
  ...createAction,
  ...actions,
};
