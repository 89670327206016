import "./TableLoader.scss"


type LoaderProps = {
  size?: "sm" | "md" | "lg";
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;


const TableLoader = ({size, ...props} : LoaderProps) => {
  return (
    <div {...props} className={`spinner-container`}>
        <div className={`spinner ${size ? `spinner-size-${size}` : `spinner-default-size`}`}></div>
    </div>
  );
};

export default TableLoader;
