import {
  CrmIcon,
  DashboardIcon,
  FleetIcon,
  HRIcon,
  OperationsIcon,
  SalesIcon,
  FinanceIcon
} from "../../assets/svgs/svg-components";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useAppUserMenuUtils = () => {
  const { t } = useTranslation();

  const [menuGroup] = useState(() => {
    const tempMenuGroup = [];
    /* menuItemDashboard */
    const menuItemAdmin = {
      id: "admin",
      title: t("AppModules.Admin"),
      url: AppRoutingConfig.APP_URL_ADMIN,
      icon: DashboardIcon,
    };
    tempMenuGroup.push(menuItemAdmin);
    /* menuItemDashboard */

    /* menuItemHR */
    const menuItemHR = {
      id: "hr",
      title: t("AppModules.HR"),
      url: AppRoutingConfig.APP_URL_HR,
      icon: HRIcon,
    };
    tempMenuGroup.push(menuItemHR);
    /* menuItemHR */

    /* menuItemOperations */
    const menuItemOperations = {
      id: "operations",
      title: t("AppModules.Operations"),
      url: AppRoutingConfig.APP_URL_OPERATIONS,
      icon: OperationsIcon,
    };
    tempMenuGroup.push(menuItemOperations);
    /* menuItemOperations */

    /* menuItemOperations */
    const menuItemSales = {
      id: "sales",
      title: t("AppModules.Sales"),
      url: AppRoutingConfig.APP_URL_SALES,
      icon: SalesIcon,
    };
    tempMenuGroup.push(menuItemSales);
    /* menuItemOperations */

    /* menuItemCrm */
    const menuItemCrm = {
      id: "crm",
      title: t("AppModules.Crm"),
      url: AppRoutingConfig.APP_URL_CRM,
      icon: CrmIcon,  
    };
    tempMenuGroup.push(menuItemCrm);
    /* menuItemCrm */

    /* menuItemFleet */
    const menuItemFleet = {
      id: "fleet",
      title: t("AppModules.Fleet"),
      url: AppRoutingConfig.APP_URL_FLEET,
      icon: FleetIcon,  
      };
      tempMenuGroup.push(menuItemFleet);
    /* menuItemFleet */

    /* menuItemFleet */
    const menuItemFinance = {
      id: "finance",
      title: t("AppModules.Finance"),
      url: AppRoutingConfig.APP_URL_FINANCE,
      icon: FinanceIcon,  
      };
      tempMenuGroup.push(menuItemFinance);
    /* menuItemFleet */

    return tempMenuGroup;
  });

  return [menuGroup];
};
