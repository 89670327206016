import React, { useEffect, useState, useTransition } from 'react'
import { useAppUserMenuUtils } from '../../../../utils/sidebarNavigationUtils/useAppUserMenuUtils'
import AppAssetsConfig from '../../../../assets/config/AppAssetsConfig'
import AppConstConfig from '../../../../assets/config/AppConstConfig'
import "./AnimatedSideBar.scss"
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Tooltip from '../../../common/tooltip'

type Props = {}

const AnimatedSideBar = (props: Props) => {
    const [menuGroup] = useAppUserMenuUtils()
    const location = useLocation()
    const {t} = useTranslation()
    const [isActiveMenuId, setIsActiveMenuId] = useState("hr")
    
    const [isPending, startTransition] = useTransition()

    /* function definition to set the active menu when the user clicks on it */
    const handleNavigationClicked = (element:any,index:number) => {
        startTransition(()=>{
            setIsActiveMenuId(element?.id)
        })
    }
    /* function definition to set the active menu when the user clicks on it */

    /* check if the module tab is already active when the component is mounted */
    useEffect(()=>{
        let moduleNames = [t("AppModules.Admin"), t("AppModules.HR"), t("AppModules.Operations"), t("AppModules.Sales"), t("AppModules.Crm"), t("AppModules.Fleet"), t("AppModules.Finance")];
        const tempPathnameArray = location.pathname.split("/");
        const tempModuleNameArr = moduleNames.map((element) => element.toLowerCase()).filter((_element) => tempPathnameArray.indexOf(_element) !== -1);
        setIsActiveMenuId(tempModuleNameArr[tempModuleNameArr?.length - 1])
    },[location.pathname])
    /* check if the module tab is already active when the component is mounted */

  return (
    <div className="navigation-container flex_box flex_box--column gap-1">
        <div className="logo-container">
        <img src={AppAssetsConfig.DEFAULT_COMPANY_LOGO} alt="Logo" className="logo-style"/>
        </div>
      <div className='navigation-menu flex_box flex_box--items-center flex_box--justify-center flex_box--column'>
        <ul className='navigation-unordered-list'>
        {
            menuGroup?.map((element,index)=>{
                return (
                    <React.Fragment key={element.id}>
                    <li className={`navigation-item-list ${isActiveMenuId === element.id.toLowerCase() ? `active${index}` : "" }`} onClick={()=>handleNavigationClicked(element, index)} >
                        <Link to={element.url} className="navigation-item-link flex_box flex_box--items-center flex_box--justify-center">
                            <span className='navigation-icon flex_box flex_box--items-center flex_box--justify-center'>
                            <Tooltip title={element?.title}>
                                <element.icon fillColor={isActiveMenuId === element.id.toLowerCase() ? AppConstConfig.SKY_BLUE_COLOR : AppConstConfig.LIGHT_GRAY_COLOR}/>
                            </Tooltip>
                            </span>
                        </Link>
                    </li>
                    </React.Fragment>
                )
            })
        }
        <div className="navigation-indicator"></div>
        </ul>
    </div>
    </div>
  )
}

export default AnimatedSideBar