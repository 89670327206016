import React, { useEffect, useRef, useState, useTransition } from "react";
import Card from "../card";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Box from "../box";
import { ClipPathIcon } from "../../../assets/svgs/svg-components";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import "./AnimatedTabs.scss";

type Props = {
  tabsMenuUtils?: { id: string; title: string; url: string }[];
  isBoxLayout?: boolean;
  disabled?: boolean;
};



const AnimatedTabs = ({ tabsMenuUtils, isBoxLayout, disabled }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  /* navigation dependencies */
  const navigate = useNavigate();
  const location = useLocation();
  /* navigation dependencies */

  const indicatorRef:any = useRef(null)
  const anchorTagRef:any = useRef(null)

  const [isPending, startTransition] = useTransition()

  useEffect(() => {
    if (tabsMenuUtils && tabsMenuUtils !== undefined) {
        startTransition(()=>{
            const tempTabMenuUtils = [...tabsMenuUtils];
            const tabIndex = tempTabMenuUtils.findIndex(
              (element) => element.url === location.pathname
            );
            setCurrentTabIndex(tabIndex);
            navigate(tempTabMenuUtils[tabIndex].url, { replace: true });
        })
    }
  }, [location.pathname]);


  useEffect(() => {
    if (anchorTagRef.current) {
      indicatorRef.current.style.left = anchorTagRef.current.offsetLeft + "px" 
      indicatorRef.current.style.width = anchorTagRef.current.offsetWidth + "px" 
    }
  }, [location.pathname, currentTabIndex]);

  /* on tab change set current tab index */
  const onTabChanged = (
    event: React.MouseEvent<HTMLElement>,
    element: { id: string; title: string; url: string },
    index: number
  ) => {
    startTransition(()=>{
        setCurrentTabIndex(index);
        indicatorRef.current.style.left = (event.target as HTMLElement).offsetLeft + "px" 
        indicatorRef.current.style.width = (event.target as HTMLElement).offsetWidth + "px" 
        navigate(element.url, { replace: true });
    })
  };
  /* on tab change set current tab index */

  return (
    <GetTabsLayout isBoxLayout={isBoxLayout}>
      <div className="tabs-wrapper">
        <nav className="tabs-container flex_box flex_box--items-center flex_box--justify-center gap-2">
          {tabsMenuUtils?.map((element, index) => {
            return (
              <a
                ref={index === currentTabIndex ? anchorTagRef : null}
                className={`tab ${
                  index === currentTabIndex ? "tab-active" : ""
                } ${disabled ? "tab-disabled" : ""}`}
                onClick={(event) => onTabChanged(event, element, index)}
              >
                {element.title}
              </a>
            );
          })}
          <div className="indicator" ref={indicatorRef}>
            <div className="indicator-container"></div>
            <div className="svg-container">
              <ClipPathIcon fillColor={AppConstConfig.APP_PRIMARY_COLOR} />
            </div>
          </div>
        </nav>
      </div>
      <Outlet />
    </GetTabsLayout>
  );
};

type GetTabsLayoutProps = {
  children: React.ReactNode;
  isBoxLayout: boolean | undefined;
};
const GetTabsLayout = ({ children, isBoxLayout }: GetTabsLayoutProps) => {
  return <>{isBoxLayout ? <Box>{children}</Box> : <Card>{children}</Card>}</>;
};

export default AnimatedTabs;
