import React, { useState } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Control, useController } from "react-hook-form";
import { CalendarIcon } from "../../../assets/svgs/svg-components";
import ErrorText from "../errorText";
import { isObjectEmpty } from "../../../utils/appCommonUtils";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import IconButton from "../iconButton";
import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.scss";

export type DatePickerProps = {
  name: string;
  control: Control<any>;
  variant?: "standard";
  onCustomChange?: (event: Date) => void;
  customIcon?: React.ReactElement;
  disabled?: boolean;
  isClearable?: boolean;
} & ReactDatePickerProps;

const Datepicker = ({
  name,
  control,
  variant,
  onCustomChange,
  customIcon,
  disabled,
  isClearable,
  ...props
}: DatePickerProps) => {
  const {
    field,
    formState: { errors },
  } = useController({ name, control });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [openDatepicker, setOpenDatepicker] = useState(false);

  /* function definition to handle on change */
  const handleChange = (date: Date, triggeredAction:any) => {
    console.log(triggeredAction,'triggeredAction')
    setOpenDatepicker((prevValue) => !prevValue);
    setIsCalendarOpen((prevValue) => !prevValue);
    field.onChange(date);
    onCustomChange && onCustomChange(date)
  };
  /* function definition to handle on change */

  return (
    <>
      <div className="datepicker-container">
        <ReactDatePicker
          className={`datepicker ${disabled && "datepicker-disabled"} ${
            variant ? `datepicker-${variant}` : "datepicker-outlined"
          } 
      ${
        errors && errors[name] && variant === "standard"
          ? `datepicker-error-${variant}`
          : errors && errors[name]
          ? "datepicker-error-outlined"
          : ""
      }`}
          id={name}
          {...field}
          {...props}
          disabled={disabled}
          selected={field.value}
          onChange={handleChange}
          onKeyDown={(e) => e.preventDefault()}
          placeholderText="mm-dd-yyyy"
          clearButtonClassName="datepicker-clear-button"
          showYearDropdown
          scrollableYearDropdown
          showMonthDropdown
          dropdownMode="select"
          onCalendarOpen={() => setIsCalendarOpen(true)}
          onCalendarClose={() => setIsCalendarOpen(false)}
          todayButton="Today"
          isClearable={
            field.value &&
            field.value !== "" &&
            field.value !== undefined &&
            field.value !== null
              ? isClearable
              : false
          }
          open={openDatepicker}
          onFocus={() => {
            setOpenDatepicker(true);
          }}
          onClickOutside={() => {
            setOpenDatepicker(false);
            setIsCalendarOpen(false);
          }}
          autoComplete="off"
        />
        {
          !disabled ?
          <div className="datepicker-custom-icon flex_box flex_box--items-center flex_box--justify-center">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setIsCalendarOpen(true);
              setOpenDatepicker((prevValue) => !prevValue);
            }}
          >
            <CalendarIcon
              fillColor={
                isCalendarOpen
                  ? AppConstConfig.APP_PRIMARY_COLOR
                  : AppConstConfig.GRAY_COLOR
              }
            />
          </IconButton>
          </div>
          :
          <></>
        }
      </div>
      {!isObjectEmpty(errors) && errors[name] ? (
        <ErrorText>{`${errors[name]?.message}`}</ErrorText>
      ) : (
        ""
      )}
    </>
  );
};

export default Datepicker;
