import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import AdminDashboardConfig from "./dashboard/DashboardConfig";
import RegionConfig from "./region/RegionConfig";
import DepartmentConfig from "./department/DepartmentConfig";
import VendorsConfig from "./vendors/VendorsConfig";
import PartnersConfig from "./partners/PartnersConfig";
import { Navigate } from "react-router";
import CustomersConfig from "./customers/CustomersConfig";

const AdminConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_ADMIN,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_ADMIN_DASHBOARD} replace />
      ),
    },
    ...AdminDashboardConfig.routes,
    ...RegionConfig.routes,
    ...DepartmentConfig.routes,
    ...VendorsConfig.routes,
    ...PartnersConfig.routes,
    ...CustomersConfig.routes
  ],
};

export default AdminConfig;
