export const GET_ROLE_SELECT_LIST = null
export const GET_SUFFIX_SELECT_LIST = null
export const GET_BASE_WAGE_TYPE_SELECT_LIST = null
export const GET_TYPE_SELECT_LIST = null
export const GET_STATUS_SELECT_LIST = null
export const GET_EDUCATION_LEVEL_SELECT_LIST = null
export const GET_EDUCATION_DEGREE_SELECT_LIST = null
export const GET_REPORTING_TO_SELECT_LIST = null
export const GET_QUOTE_TYPE_SELECT_LIST = null
export const GET_PROBABILITY_SELECT_LIST = null
export const GET_LEAD_SOURCE_SELECT_LIST = null
export const GET_CUSTOMER_TYPE_SELECT_LIST = null
export const GET_STAGE_SELECT_LIST = null
export const GET_QUOTE_STATUS_SELECT_LIST = null
export const GET_ACTIVITY_TYPE_SELECT_LIST = null
export const GET_UNIT_OF_MEASURE_SELECT_LIST = null
export const GET_TITLE_SELECT_LIST = null
export const RESET_TITLE_SELECT_LIST = null
export const SELECTED_NOTES = null
export const EMPTY_SELECTED_NOTES = null

/* Common action for confirm status */
export const CONFIRM_STATUS = null
/* Common action for confirm status */

export const FILTER_EMPLOYEE_MANAGEMENT_EDUCATION_LEVEL_SELECT_LIST = null