import { Link, Route, RouterProvider, Routes } from "react-router-dom";
import { RoutesConfig } from "./configs/RoutesConfig";
import Loader from "../components/common/loader";
import { Suspense } from "react";
import "./App.scss";
import ToastProvider from "../components/common/toast/ToastProvider";
import { AxiosInterceptor } from "../axios/axiosInstance";

function App() {
  return (
    <>
        <div className="App">
      <Suspense fallback={<Loader />}>
          <ToastProvider>
            <RouterProvider router={RoutesConfig} />
          </ToastProvider>
      </Suspense>
        </div>
    </>
  );
}

export default App;
