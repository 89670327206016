import axiosConfig from '../../../axios/axiosConfig'
import AppApiConfig from '../../../assets/config/AppApiConfig'
import axiosConfigInstance from '../../../axios/axiosInstance'

export const GET_PROJECT_SUMMARY_URL = AppApiConfig.API_PROJECT_SUMMARY_LIST_URL
export const ADD_PROJECT_SUMMARY_URL = AppApiConfig.API_SAVE_PROJECT_SUMMARY_MODULE_URL
export const GET_PROJECT_ID = AppApiConfig.API_GENERATE_PROJECT_ID_PROJECT_SUMMARY_MODULE_URL
export const GET_CUSTOMER_LIST = AppApiConfig.API_CUSTOMER_LIST_PROJECT_SUMMARY_MODULE_URL
export const GET_STATUS_LIST = AppApiConfig.API_OPERATION_STATUS_LIST_SELECT_LIST_URL
export const GET_PROJECT_SUMMARY_DETAIL_URL = AppApiConfig.API_LOAD_PROJECT_SUMMARY_DETAIL_URL
export const GET_PROJECT_LIST_URL = AppApiConfig.API_PROJECT_LIST_URL


export const getProjectSummarys = (dataToBeSent:{isActive:boolean,pageNumber:number,pageSize:number,orderBy:boolean,searchString:String}) => {
  return axiosConfigInstance.post(
    GET_PROJECT_SUMMARY_URL,
    dataToBeSent
  )
}

export const addProjectSummarysDetails = (dataToBeSent:{id: number,
  loginUserId: number,
  customerId: number,
  projectNo: string,
  projectName: string,
  serviceOrderNo: string,
  po: string,
  addressLine1: string,
  addressLine2: string,
  country: number,
  state: number,
  city: number,
  zipCode: string,
  status: string,
  amount: number,
  startDate: string,
  endDate: string,
  typeofWork: string,
  workDetails: string
}) => {
  return axiosConfigInstance.post(
    ADD_PROJECT_SUMMARY_URL,
    dataToBeSent
  )
  }
  
export const getProjectId = () => {
  return axiosConfigInstance.post(
    GET_PROJECT_ID
  )
}

  
export const getCustomerSelectList = (dataToBeSent:{RegionId:string}) => {
  return axiosConfigInstance.post(
    GET_CUSTOMER_LIST,{},{params:dataToBeSent}
    )
}

export const getStatusSelectList = () => {
  return axiosConfigInstance.post(
    GET_STATUS_LIST
    )
}


export const getProjectSummaryDetail = (dataToBeSent:{projectId: number}) => {
  return axiosConfigInstance.post(
    GET_PROJECT_SUMMARY_DETAIL_URL,{},{params:dataToBeSent}
  )
}


export const getProjectSelectLists = (dataToBeSent:{CustomerId:string}) => {
  return axiosConfigInstance.post(
    GET_PROJECT_LIST_URL,{},{params:dataToBeSent}
  )
}


export const  activeInactiveProjectSummary = (dataToBeSent:{projectId:number, isActive: boolean}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_ACTIVE_INACTIVE_PROJECT_SUMMARY_URL,
    dataToBeSent
  )
}

export const  updateProjectSummaryStatus = (dataToBeSent:{projectId:number, status: string}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_UPDATE_PROJECT_SUMMARY_STATUS_URL,
    dataToBeSent
  )
}