import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";

export const useCrmMenuUtils = () => {
  /* translation dependencies */
  const { t } = useTranslation();
  /* translation dependencies */

  const [crmMenuGroup] = useState(() => {
    const tempMenuGroup = [];

    /* menuItemCrmDashboard */
    const menuItemCrmDashboard = {
      id: "crmDashboard",
      title: t("AppModules.CrmSubModules.CrmDashboard"),
      url: AppRoutingConfig.APP_URL_CRM_DASHBOARD,
    };
    tempMenuGroup.push(menuItemCrmDashboard);
    /* menuItemCrmDashboard */

    /* menuItemLeadpool */
    const menuItemLeadpool = {
      id: "leadPool",
      title: t("AppModules.CrmSubModules.Leadpool"),
      url: AppRoutingConfig.APP_URL_CRM_LEADPOOL_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemLeadpool);
    /* menuItemLeadpool */

    /* menuItemAgents */
    const menuItemAgents = {
      id: "agents",
      title: t("AppModules.CrmSubModules.Agents"),
      url: AppRoutingConfig.APP_URL_CRM_AGENTS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemAgents);
    /* menuItemAgents */

    /* menuItemCampaigns */
    const menuItemCampaigns = {
      id: "campaigns",
      title: t("AppModules.CrmSubModules.Campaigns"),
      url: AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemCampaigns);
    /* menuItemCampaigns */

    return tempMenuGroup;
  });

  return [crmMenuGroup];
};
