import axios from 'axios'
import AppApiConfig from '../assets/config/AppApiConfig';
import UserSessionService from '../services/UserSessionService';
// Add a request interceptor

const axiosConfig = axios.create({
  baseURL: AppApiConfig.API_ENDPOINT
});


axiosConfig.interceptors.request.use(
  function (config) {
    const token = UserSessionService.getUserSessionToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
axiosConfig.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
    }
    return Promise.reject(error)
  },
)
 
export default axiosConfig;