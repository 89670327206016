import Actions from "../actions";
import { produce } from "immer";

interface State {
  salesRep: {
    totalCount: number;
    salesRepDetailList: [];
  };
  selectedSalesRepId:number;
  leads: {
    repId: string;
    salesRep: string;
    leadDetailsList: [];
  };
}
const initialState: State = {
    salesRep: {
      totalCount: 0,
      salesRepDetailList: [],
    },
    selectedSalesRepId:0,
    leads: {
      repId: "",
      salesRep: "",
      leadDetailsList: []
    }
};

const salesRep = produce((draft: State, action: any) => {
    const { payload } = action;
    switch (action.type) {
      case Actions.GET_SALES_REP_LIST: {
        draft.salesRep = payload?.salesRep;
        break;
      }

      case Actions.SELECTED_SALES_REP_ID: {
        draft.selectedSalesRepId = payload?.selectedSalesRepId;
        break;
      }

      case Actions.GET_LEAD_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.leads = payload?.leads;
        break;
      }

      case Actions.EMPTY_LEAD_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.leads = {
          repId: "",
          salesRep: "",
          leadDetailsList: []
        };
        break;
      }

      default: {
        break;
      }
    }
  }, initialState);
  
export default salesRep;