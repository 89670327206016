import React, { FC, useEffect, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import {
  Controller,
  UseControllerProps,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorText from "../../../../common/errorText";
import Select from "../../../../common/select";
import Button from "../../../../common/button";
import SelectComponent from "../../../../common/selectComponent";
import TableLoader from "../../../../common/tableLoader";
import InputField from "../../../../common/inputField";
import TextArea from "../../../../common/textArea";
import CheckBox from "../../../../common/checkbox";
import Actions from "../../../../../redux/actions";
import {
  checkForValidResponse,
  getFilteredObjectFromId,
} from "../../../../../utils/appCommonUtils";
import {
  getCitySelectListAction,
  getCountrySelectListAction,
  getStateSelectListAction,
} from "../../../../../redux/services/region/regionServices";
import {
  addEditCustomerManagementAction,
  getCustomerByIdAction,
  getCustomerIdAction,
} from "../../../../../redux/services/customerManagement/customerManagementServices";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { useToast } from "../../../../../hooks/useToast";
import { allowCharactersOnlyForIntegerNumbers } from "../../../../../utils/appKeyStrokeLimitingUtils";
import "./CustomerManagement.scss";

type Props = {};

const CustomerManagementForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  /* For navigating between pages */

  /* searchable select dependencies */
  const { countrySelectList, stateSelectList, citySelectList } = useAppSelector(
    (state) => state?.region
    );
  /* searchable select dependencies */

  /* Dependencies for default values and validation */
  type SelectListProps = { value: any; label: string };

  type DefaultValueTypes = {
    id: number;
    customerId: string;
    customerName: string;
    addressLine1: string;
    addressLine2: string;
    country: null | any;
    state: null | any;
    city: null | any;
    zipCode: string;
    phone: string;
    fax: string;
    email: string;
    website: string;
    billingAddressLine1: string;
    billingAddressLine2: string;
    billingCountry: null;
    billingState: null;
    billingCity: any|null;
    billingZipcode: string;
    contactName: string;
    jobTitle: string;
    contactPosition: string;
    contactWorkPhone: string;
    contactMobile: string;
    contactEmail: string;
    isSameAddress: boolean;
  };

  const defaultValues: DefaultValueTypes = {
    id: 0,
    customerId: "",
    customerName: "",
    addressLine1: "",
    addressLine2: "",
    country: null,
    state: null,
    city: null,
    zipCode: "",
    phone: "",
    fax: "",
    email: "",
    website: "",
    billingAddressLine1: "",
    billingAddressLine2: "",
    billingCountry: null,
    billingState: null,
    billingCity: null,
    billingZipcode: "",
    contactName: "",
    jobTitle: "",
    contactPosition: "",
    contactWorkPhone: "",
    contactMobile: "",
    contactEmail: "",
    isSameAddress: false
  };

  const schema = yup.object().shape({
    customerId: yup.string().max(20).required(t("Operations.CustomerManagement.CustomerManagementForm.CustomerIdIsRequired")),
    customerName: yup.string()
      .max(25, `${t("Operations.CustomerManagement.CustomerManagementForm.CustomerName")} ${t(`CommonUtils.Validations.MustNotExceed25Characters`)}`)
      .required(t("Operations.CustomerManagement.CustomerManagementForm.CustomerNameIsRequired")),
    addressLine1: yup.string()
      .max(500, `${t("Operations.CustomerManagement.CustomerManagementForm.AddressLine1")} ${t(`CommonUtils.Validations.MustNotExceed500Characters`)}`)
      .required(t("Operations.CustomerManagement.CustomerManagementForm.AddressLine1IsRequired")),
    country: yup.object().required(t("Operations.CustomerManagement.CustomerManagementForm.CountryIsRequired")),
    state: yup.object().required(t("Operations.CustomerManagement.CustomerManagementForm.StateIsRequired")),
    zipCode: yup.string()
      .max(10, `${t("Operations.CustomerManagement.CustomerManagementForm.ZipCode")} ${t(`CommonUtils.Validations.MustNotExceed10Characters`)}`)
      .required(t("Operations.CustomerManagement.CustomerManagementForm.ZipCodeIsRequired")),
    phone: yup.string()
      .max(20, `${t("Operations.CustomerManagement.CustomerManagementForm.Phone")} ${t(`CommonUtils.Validations.ShouldNotExceed20Numbers`)}`)
      .required(t("Operations.CustomerManagement.CustomerManagementForm.PhoneIsRequired")),
    email: yup.string()
      .email(t("CommonUtils.Validations.PleaseEnterAValidEmail"))
      .max(50, `${t("Operations.CustomerManagement.CustomerManagementForm.Email")} ${t(`CommonUtils.Validations.MustNotExceed50Characters`)}`)
      .required( t("Operations.CustomerManagement.CustomerManagementForm.EmailIsRequired")),
    billingAddressLine1: yup.string()
      .max(500, `${t("Operations.CustomerManagement.CustomerManagementForm.AddressLine1")} ${t(`CommonUtils.Validations.MustNotExceed500Characters`)}`)
      .required(t("Operations.CustomerManagement.CustomerManagementForm.AddressLine1IsRequired")),
    billingCountry: yup.object().required(t("Operations.CustomerManagement.CustomerManagementForm.CountryIsRequired")),
    billingState: yup.object().required(t("Operations.CustomerManagement.CustomerManagementForm.StateIsRequired")),
    billingZipcode: yup.string()
      .max(10, `${t("Operations.CustomerManagement.CustomerManagementForm.ZipCode")} ${t(`CommonUtils.Validations.MustNotExceed10Characters`)}`)
      .required(t("Operations.CustomerManagement.CustomerManagementForm.ZipCodeIsRequired")),
    contactName: yup.string()
    .max(25, `${t("Operations.CustomerManagement.CustomerManagementForm.Name")} ${t(`CommonUtils.Validations.MustNotExceed25Characters`)}`)
    .required(t("Operations.CustomerManagement.CustomerManagementForm.NameIsRequired")),
    contactMobile: yup.string()
    .max(20, `${t("Operations.CustomerManagement.CustomerManagementForm.MobilePhone")} ${t(`CommonUtils.Validations.ShouldNotExceed20Numbers`)}`)
    .required(t("Operations.CustomerManagement.CustomerManagementForm.MobilePhoneIsRequired")),
    contactEmail: yup.string()
    .max(50, `${t("Operations.CustomerManagement.CustomerManagementForm.Email")} ${t(`CommonUtils.Validations.MustNotExceed50Characters`)}`)
    .required(t("Operations.CustomerManagement.CustomerManagementForm.EmailIsRequired"))
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const form = watch();
  const { isSameAddress, customerId, addressLine1, country, state, zipCode } = form;
  /* Dependencies for default values and validation */

  const selectListApiCalls = (details: any) => {

    // Country select list
    dispatch(getCountrySelectListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.country, apiRes?.data).then(
            (country: any) => {
              country && loadDispatchStateForCountry(country);

              setValue("country", country);
            }
          );

        getFilteredObjectFromId(details?.billingCountry, apiRes?.data).then(
          (billingCountry: any) => {
            billingCountry && loadDispatchStateForCountry(billingCountry);

            setValue("billingCountry", billingCountry);
          }
        );
      }
    );

    // State select list based on country
    const loadDispatchStateForCountry = (country: {
      value: any;
      label: string;
    }) => {
      dispatch(getStateSelectListAction({CountryId: country?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.state, apiRes?.data).then(
            (state: any) => {
              state && loadDispatchCityForState(state);
              setValue("state", state);
            }
          );

        getFilteredObjectFromId(details?.billingState, apiRes?.data).then(
          (billingState: any) => {
            billingState && loadDispatchCityForState(billingState);
            setValue("billingState", billingState);
          }
        );
      });
    };

    // City select list based on state
    const loadDispatchCityForState = (state: { value: any; label: string }) => {
      dispatch(getCitySelectListAction({StateId: state?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.city, apiRes?.data).then(
            (city: any) => {
              setValue("city", city);
            }
          );

        getFilteredObjectFromId(details?.billingCity, apiRes?.data).then(
          (billingCity: any) => {
            setValue("billingCity", billingCity);
          }
        );
      });
    };
  };

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);
      //Generated Customer ID
      dispatch(getCustomerIdAction()).then((apiRes) => {
          setValue("customerId", apiRes?.data);
        }
      );
    }
  }, []);
  /* load initial dependencies - Add */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { customerManagementId } = params;

      const dataToBeSent = { CustomerId: customerManagementId };
      setLoadingComponent(true)
      dispatch(getCustomerByIdAction(dataToBeSent)).then(
        (apiRes: any) => {
          const customerDetail = apiRes?.data;
          if (customerDetail) {
            selectListApiCalls(customerDetail);

            const {country, state, city, billingCountry, billingState, billingCity, isSameAddress, ...rest} = customerDetail;
            reset({
              ...rest,
              isSameAddress,
            });
            setLoadingComponent(false)
          }
        }
      );
    }
  }, []);
  /* load initial dependencies - Edit */

  /* On Country changed function */
  const onCountryChanged = (
    option: SelectListProps,
    isClearAction: boolean
  ) => {
    if (option && option !== null && option !== undefined) {
      loadStateForCountry(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CITY_SELECT_LIST));
      dispatch(Actions.createAction(Actions.RESET_STATE_SELECT_LIST));
    }
    setValue("state", null);
    setValue("city", null);
  };
  /* for loading region code based on country */

  const loadStateForCountry = (option: SelectListProps) => {
    const dataToBeSent = {
      CountryId: option.value,
    };
    dispatch(getStateSelectListAction(dataToBeSent))
  };
  /* On Country changed function */

  /* On Billing Country changed function */
  const onBillingCountryChanged = (
    option: SelectListProps,
    isClearAction: boolean
  ) => {
    if (option && option !== null && option !== undefined) {
      loadBillingStateForCountry(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CITY_SELECT_LIST));
      dispatch(Actions.createAction(Actions.RESET_STATE_SELECT_LIST));
    }
    setValue("billingState", null);
    setValue("billingCity", null);
    setValue("isSameAddress", false)
  };
  /* for loading region code based on country */

  const loadBillingStateForCountry = (option: SelectListProps) => {
    const dataToBeSent = {
      CountryId: option.value,
    };
    dispatch(getStateSelectListAction(dataToBeSent));
  };
  /* On Billing Country changed function */

  /* On State changed function */
  const onStateChanged = (option: SelectListProps, isClearAction: boolean) => {
    if (option && option !== null && option !== undefined) {
      loadCityForState(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CITY_SELECT_LIST));
    }
    setValue("city", null);
  };
  /* for loading city code based on state */
  const loadCityForState = (option: SelectListProps) => {
    const dataToBeSent = {
      StateId: option.value,
    };
    dispatch(getCitySelectListAction(dataToBeSent));
  };
  /* for loading city code based on state */
  /* On State changed function */

  /* On Billing State changed function */
  const onBillingStateChanged = (
    option: SelectListProps,
    isClearAction: boolean
  ) => {
    if (option && option !== null && option !== undefined) {
      loadBillingCityForState(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CITY_SELECT_LIST));
    }
    setValue("billingCity", null);
    setValue("isSameAddress", false)
  };
  /* for loading city code based on state */
  const loadBillingCityForState = (option: SelectListProps) => {
    const dataToBeSent = {
      StateId: option.value,
    };
    dispatch(getCitySelectListAction(dataToBeSent));
  };
  /* for loading city code based on state */
  /* On Billing State changed function */

  /*function for billing address same as above*/
  const onSameAsAboveChanged = (event: React.SyntheticEvent) => {
    if ((event?.target as HTMLInputElement).checked) {
      reset({
        ...form,
        isSameAddress: (event?.target as HTMLInputElement).checked,
        billingCountry: form.country,
        billingState: form.state,
        billingCity: form.city,
        billingZipcode: form.zipCode,
        billingAddressLine1: form.addressLine1,
        billingAddressLine2: form.addressLine2,
      });
    } else {
      reset({
        ...form,
        isSameAddress: (event?.target as HTMLInputElement).checked,
        billingCountry: null,
        billingState: null,
        billingCity: null,
        billingZipcode: "",
        billingAddressLine1: "",
        billingAddressLine2: "",
      });
    }
  };
  /*function for billing address same as above*/

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    const {
      country,
      state,
      city,
      billingCountry,
      billingState,
      billingCity,
      ...rest
    } = formData;

    const dataToBeSent = {
      ...rest,

      country: country && country.value,
      state: state && state.value,
      city: city ? city.value:0,

      billingCountry: billingCountry && billingCountry.value,
      billingState: billingState && billingState.value,
      billingCity: billingCity ? billingCity?.value:0,
      regionId:null
    };
    dispatch(addEditCustomerManagementAction(showToast,navigate,setLoadingComponent,dataToBeSent,location));
  };
  /* Form submit handler */
  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="customerManagementForm"
        id="customerManagementForm"
      >
        <Grid container spacing="md" flexDirection="column">
          {/* Customer Info */}
          <Grid item>
            <Card>
              <div className="customer-management-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>
                    {t(
                      "Operations.CustomerManagement.CustomerManagementForm.CustomerInfo"
                    )}
                  </Title>
                </Grid>

                <Grid container spacing="sm">
                  {/* Customer Id */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.CustomerId"
                      )}
                    </Label>
                    <InputField
                      name="customerId"
                      disabled
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterCustomerId"
                      )}
                      control={control}
                    />
                  </Grid>
                  {/* Customer Name */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.CustomerName"
                      )}
                    </Label>
                    <InputField
                      name="customerName"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterCustomerName"
                      )}
                      control={control}
                    />
                  </Grid>
                  {/* Address Line 1 */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.AddressLine1"
                      )}
                    </Label>
                    <InputField
                      name="addressLine1"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterAddressLine1"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.AddressLine2"
                      )}
                    </Label>
                    <InputField
                      name="addressLine2"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterAddressLine2"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Country"
                      )}
                    </Label>
                    <Select
                      name="country"
                      options={countrySelectList}
                      control={control}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.SelectCountry"
                      )}
                      onCustomChange={(option, isClearAction) => {
                        onCountryChanged(option, isClearAction);
                      }}
                      isLoading={apiLoader}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.State"
                      )}
                    </Label>
                    <Select
                      name="state"
                      options={stateSelectList}
                      control={control}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.SelectState"
                      )}
                      onCustomChange={(option, isClearAction) => {
                        onStateChanged(option, isClearAction);
                      }}
                      isClearable
                      isLoading={apiLoader}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label >
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.City"
                      )}
                    </Label>
                    <Select
                      name="city"
                      options={citySelectList}
                      control={control}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.SelectCity"
                      )}
                      isClearable
                      isLoading={apiLoader}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.ZipCode"
                      )}
                    </Label>
                    <InputField
                      name="zipCode"
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterZipCode"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Phone"
                      )}
                    </Label>
                    <InputField
                      name="phone"
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterPhone"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Fax"
                      )}
                    </Label>
                    <InputField
                      name="fax"
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterFax"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Email"
                      )}
                    </Label>
                    <InputField
                      name="email"
                      type="email"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterEmail"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Website"
                      )}
                    </Label>
                    <InputField
                      name="website"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterWebsite"
                      )}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <div className="customer-management-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>
                    {t(
                      "Operations.CustomerManagement.CustomerManagementForm.BillingAddress"
                    )}
                  </Title>
                </Grid>

                <Grid container spacing="sm">
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Grid container>
                      <Grid item sm={12} md={6} lg={5}>
                        <Label required>
                          {t(
                            "Operations.CustomerManagement.CustomerManagementForm.AddressLine1"
                          )}
                        </Label>
                      </Grid>
                      <Grid item sm={12} md={6} lg={7}>
                        <CheckBox
                          name="isSameAddress"
                          direction="row"
                          value="Same as above"
                          checked={isSameAddress}
                          disabled={!addressLine1 || !country?.label || !state?.label || !zipCode}
                          control={control}
                          onCustomChange={(e) => onSameAsAboveChanged(e)}
                        />
                      </Grid>
                    </Grid>
                    <InputField
                      name="billingAddressLine1"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterAddressLine1"
                      )}
                      control={control}
                      onCustomChange={()=>setValue("isSameAddress", false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.AddressLine2"
                      )}
                    </Label>
                    <InputField
                      name="billingAddressLine2"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterAddressLine2"
                      )}
                      control={control}
                      onCustomChange={()=>setValue("isSameAddress", false)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Country"
                      )}
                    </Label>
                    <Select
                      name="billingCountry"
                      options={countrySelectList}
                      control={control}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.SelectCountry"
                      )}
                      onCustomChange={(option, isClearAction) => {
                        onBillingCountryChanged(option, isClearAction);
                      }}
                      isClearable
                      isLoading={apiLoader}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.State"
                      )}
                    </Label>
                    <Select
                      name="billingState"
                      options={stateSelectList}
                      control={control}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.SelectState"
                      )}
                      onCustomChange={(option, isClearAction) => {
                        onBillingStateChanged(option, isClearAction);
                      }}
                      isClearable
                      isLoading={apiLoader}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label >
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.City"
                      )}
                    </Label>
                    <Select
                      name="billingCity"
                      options={citySelectList}
                      control={control}
                      placeholder={t("Operations.CustomerManagement.CustomerManagementForm.SelectCity")}
                      onCustomChange={() => {
                        setValue("isSameAddress", false)
                      }}
                      isClearable
                      isLoading={apiLoader}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.ZipCode"
                      )}
                    </Label>

                    <InputField
                      name="billingZipcode"
                      control={control}
                      placeholder={t("Operations.CustomerManagement.CustomerManagementForm.EnterZipCode")}
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      onCustomChange={()=>setValue("isSameAddress", false)}
                    />
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <div className="customer-management-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>
                    {t(
                      "Operations.CustomerManagement.CustomerManagementForm.Contact"
                    )}
                  </Title>
                </Grid>

                <Grid container spacing="sm">
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Name"
                      )}
                    </Label>
                    <InputField
                      name="contactName"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterName"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.JobTitle"
                      )}
                    </Label>
                    <InputField
                      name="contactPosition"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterJobTitle"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.WorkPhone"
                      )}
                    </Label>
                    <InputField
                      name="contactWorkPhone"
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterWorkPhone"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.MobilePhone"
                      )}
                    </Label>
                    <InputField
                      name="contactMobile"
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterMobilePhone"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Email"
                      )}
                    </Label>
                    <InputField
                      name="contactEmail"
                      type="email"
                      placeholder={t(
                        "Operations.CustomerManagement.CustomerManagementForm.EnterEmail"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Button type="submit" form="customerManagementForm">
                      {t("CommonUtils.Button.Submit")}
                    </Button>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() =>
                        reset({ ...defaultValues, customerId: customerId })
                      }
                    >
                      {t("CommonUtils.Button.Reset")}
                    </Button>
                    <Button variant="outlined" onClick={()=>{
                      if(location.pathname.includes("sales")){
                        navigate(AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST);
                      }
                      else{
                        navigate(AppRoutingConfig.APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST);
                      }
                    }}>
                      {t("CommonUtils.Button.Back")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CustomerManagementForm;
