import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";

export const useFinanceMenuUtils = () => {
    /* translation dependencies */
    const { t } = useTranslation();
    /* translation dependencies */
  
    const [financeMenuGroup] = useState(() => {
       const tempMenuGroup = [];
      
       /* menuItemFinanceDashboard  */
        const menuItemFinanceDashboard = {
            id: "financeDashboard",
            title: t("AppModules.FinanceSubModules.FinanceDashboard"),
            url: AppRoutingConfig.APP_URL_FINANCE_DASHBOARD,
          };
        tempMenuGroup.push(menuItemFinanceDashboard);
       /* menuItemFinanceDashboard  */

       return tempMenuGroup;
    })

    return [financeMenuGroup];
  };
  