import { useEffect, useState } from "react";
import { Params, useLocation, useMatches } from "react-router";
import { ChevronIcon } from "../../../assets/svgs/svg-components";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import "./HeaderBreadCrumb.scss";
import { useTranslation } from "react-i18next";

export default function HeaderBreadCrumb() {

  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* crumb dependencies */
  let matches = useMatches();
  /* crumb dependencies */

  /* location dependencies */
  const location = useLocation();
  /* location dependencies */

  const [moduleNameArr, setModuleNameArr] = useState<string[]>([]);

  /* initially setting the bread crumb with the help of useMatches hook */
  type MatchTypeProps = {
    id: string;
    pathname: string;
    params: Params<string>;
    data: unknown;
    handle: any;
  };
  let crumbs = matches
    .filter((match: MatchTypeProps) => Boolean(match.handle?.crumb))
    .map((match: MatchTypeProps) => match.handle.crumb(match.data));
  /* initially setting the bread crumb with the help of useMatches hook */

  useEffect(() => {
    getModuleNameAsCrumb();
  }, [location.pathname]);

  /* function definition to get the default module name as bread crumb */
  const getModuleNameAsCrumb = () => {
    let moduleNames = [t("AppModules.Admin"), t("AppModules.HR"), t("AppModules.Operations"), t("AppModules.Sales"),t("AppModules.Crm"), t("AppModules.Fleet"), t("AppModules.Finance")];
    const tempPathnameArray = location.pathname.split("/");
    const tempModuleNameArr = moduleNames
    .map((element) => element.toLowerCase())
    .filter((_element) => tempPathnameArray.indexOf(_element) !== -1);
    setModuleNameArr(tempModuleNameArr);
  };
  /* function definition to get the default module name as bread crumb */

  return (
    <div className="bread-crumb-container flex_box gap-1">
      <div className="bread-crumb flex_box gap-1 flex_box--items-center flex_box--justify-center">
        <span className="bread-crumb-text bread-crumb-main-text">
          {moduleNameArr[moduleNameArr?.length - 1]}
        </span>
      </div>
      <div className="icon-container flex_box flex_box--items-center flex_box--justify-center">
        <ChevronIcon fillColor={AppConstConfig.GRAY_COLOR} />
      </div>
      {crumbs?.map((crumb, index, arr) => {
        return (
          <div key={crumb} className="bread-crumb flex_box gap-1 flex_box--items-center flex_box--justify-center">
            <span className="bread-crumb-text" key={index}>
              {crumb}
            </span>
          </div>
        );
      })}
    </div>
  );
}
