import AppRoutingConfig from "../assets/config/AppRoutingConfig";

export const appFleetMenuUtils = () => {
    const menuGroup = [];
  
    /* menuItemFleetDashboard  */
    const menuItemFleetDashboard = {
      id: "fleetDashboard",
      title: "Dashboard",
      url: AppRoutingConfig.APP_URL_FLEET_DASHBOARD,
    };
    menuGroup.push(menuItemFleetDashboard);
    /* menuItemFleetDashboard  */
       
    /* menuItemFleetManagement */
    const menuItemFleetManagement = {
      id: "fleetManagement",
      title: "Fleet Management",
      url: AppRoutingConfig.APP_URL_FLEET_FLEET_MANAGEMENT_MODULE_LIST,
    };
    menuGroup.push(menuItemFleetManagement);
    /* menuItemFleetManagement */
  

    return menuGroup;
  };
  