import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";

export const useSalesMenuUtils = () => {
    /* translation dependencies */
    const { t } = useTranslation();
    /* translation dependencies */

  const [salesMenuGroup] = useState(() => {
    const tempMenuGroup = [];

    /* menuItemHrDashboard */
    // const menuItemSalesDashboard = {
    //   id: "salesDashboard",
    //   title: t("AppModules.SalesSubModules.SalesDashboard"),
    //   url: AppRoutingConfig.APP_URL_SALES_DASHBOARD,
    // };
    // tempMenuGroup.push(menuItemSalesDashboard);
    /* menuItemHrDashboard */

    /* menuItemCustomers */
    const menuItemCustomers = {
      id: "customers",
      title: t("AppModules.SalesSubModules.Customers"),
      url: AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemCustomers);
    /* menuItemCustomers */

    /* menuItemLead */
    const menuItemLead = {
      id: "lead",
      title: t("AppModules.SalesSubModules.Lead"),
      url: AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemLead);
    /* menuItemLead */

    /* menuItemQuotes */
    const menuItemQuotes = {
      id: "quotes",
      title: t("AppModules.SalesSubModules.Quotes"),
      url: AppRoutingConfig.APP_URL_SALES_QUOTES_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemQuotes);
    /* menuItemQuotes  */

    /* menuItemProjects */
    const menuItemProjects = {
      id: "projects",
      title: t("AppModules.SalesSubModules.Projects"),
      url: AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemProjects);
    /* menuItemProjects */

    /* menuItemSalesRep */
    // const menuItemSalesRep = {
    //     id: "salesRep",
    //     title: t("AppModules.SalesSubModules.SalesRep"),
    //     url: AppRoutingConfig.APP_URL_SALES_REP_MODULE_LIST,
    //   };
    //   tempMenuGroup.push(menuItemSalesRep);
      /* menuItemSalesRep */

    return tempMenuGroup;
  });

  return [salesMenuGroup];
};
