import React, { useEffect, useRef, useState } from "react";
import "./Button.scss";

type Props = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?:'standard' | 'outlined';
  form?:string;
  width?:'full';
  disabled?:boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({ children, onClick, variant, form, width, disabled, ...props }: Props) => {
  const [coords, setCoords] = React.useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = React.useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const handleButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setCoords({ x: event.clientX - rect.left, y: event.clientY - rect.top });
    onClick && onClick(event);
  };

  return (
    <button
      form={form}
      className={`${disabled ? 'button-disabled' : ""} button button-${width}  ${variant ? `button-variant-${variant}` : 'button-variant-standard'}`}
      onClick={handleButtonClicked}
      {...props}
    >
      {isRippling ? (
        <span
          className="ripple"
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      ) : (
        ""
      )}
      <span className="content">{children}</span>
    </button>
  );
};

export default Button;
