
  
  export const appAdminMenuUtils = () => {
    const menuGroup = [];
  
    /* menuItemDashboard */
    const menuItemAdminDashboard = {
      id: "adminDashboard",
      title: "Dashboard",
      url: "/admin/dashboard"
    };
    menuGroup.push(menuItemAdminDashboard);
    /* menuItemDashboard */  

    /* menuItemRegion */
    const menuItemAdminRegion = {
      id: "region",
      title: "Region",
      url: "/admin/region"
    };
    menuGroup.push(menuItemAdminRegion);
    /* menuItemRegion */  

    /* menuItemDepartment */
    const menuItemAdminDepartment = {
      id: "department",
      title: "Department",
      url: "/admin/department",
    };
    menuGroup.push(menuItemAdminDepartment);
    /* menuItemDepartment */  

    /* menuItemCustomers */
    const menuItemAdminCustomers = {
      id: "customers",
      title: "Customers",
      url: "/admin/customers",
    };
    menuGroup.push(menuItemAdminCustomers);
    /* menuItemCustomers */  

    /* menuItemAdminVendors */
    const menuItemAdminVendors = {
      id: "vendors",
      title: "Vendors",
      url: "/admin/vendors",
    };
    menuGroup.push(menuItemAdminVendors);
    /* menuItemAdminVendors */  

    /* menuItemAdminPartners */
    const menuItemAdminPartners = {
      id: "partners",
      title: "Partners",
      url: "/admin/partners",
    };
    menuGroup.push(menuItemAdminPartners);
    /* menuItemAdminPartners */  
  
    return menuGroup
  };
  
  