import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import HrDashboardConfig from "./dashboard/DashboardConfig";
import EmployeeManagementConfig from "./employeeManagement/EmployeeManagementConfig";
import DocumentManagementConfig from "./documentManagement/DocumentManagementConfig";
import LeaveManagementConfig from "./leaveManagement/LeaveManagementConfig";
import AttendanceManagementConfig from "./attendanceManagement/AttendanceManagementConfig";
import { Navigate } from "react-router";

const HrConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_HR,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_HR_DASHBOARD} replace />
      ),
    },
    ...HrDashboardConfig.routes,
    ...EmployeeManagementConfig.routes,
    ...DocumentManagementConfig.routes,
    ...LeaveManagementConfig.routes,
    ...AttendanceManagementConfig.routes,
  ]
};

export default HrConfig;
