import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./workflowApis";
import { Dispatch, SetStateAction } from "react";
import { AxiosResponse } from "axios";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import { createService } from "../createService/createService";

/* GET PROJECT SCHEDULING */

export const getProjectSchedulingAction =
  (
    dataToBeSent: {
      isActive: null;
      pageNumber: number;
      pageSize: number;
      orderBy: boolean;
      searchString: string;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectSchedulings(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(
          Actions.GET_PROJECT_SCHEDULING_LIST,
          response?.data
        )
      );

    return response;
  };

/* GET PROJECT SCHEDULING */

/*ADD PROJECT SCHEDULING */
export const addProjectSchedulingAction =
  (
    showToast: (value: { message: string; variant: string }) => void,
    navigate: NavigateFunction,
    dataToBeSent: any
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addProjectSchedulings(dataToBeSent)
    if (response) {
      navigate(AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST);
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }

    return response;
  };
/*ADD PROJECT SCHEDULING */
/*EDIT PROJECT SCHEDULING STATUS */

export const editProjectSchedulingStatusAction =
  (
    dataToBeSent: {
      projectScheduleId: number;
      status: string;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.editProjectSchedulingStatus(dataToBeSent)
    return response;
  };
/*EDIT PROJECT SCHEDULING STATUS */
/*GET PROJECT SCHEDULING DETAIL*/

export const getProjectSchedulingDetailAction =
  (
    dataToBeSent: { ProjectScheduleId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectSchedulingDetail(dataToBeSent)

    response &&
      dispatch(
        Actions.createAction(Actions.GET_PROJECT_SCHEDULING_DETAIL, {
          projectScheduleDetail: response?.data,
        })
      );
    return response;
  };
/*GET PROJECT SCHEDULING DETAIL*/
/*ACTIVE INACTIVE PROJECT SCHEDULING */

export const activeInactiveProjectSchedulingAction =
  (
    dataToBeSent: {
      projectScheduleId: number;
      isActive: boolean;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.activeInactiveProjectScheduling(dataToBeSent)
    return response;
  };

/*ACTIVE INACTIVE PROJECT SCHEDULING */

/* GET FIELD DATA*/

export type GetFieldDataDetailListTypes = {
  isActive: null;
  pageNumber: number;
  pageSize: number;
  orderBy: boolean;
  searchString: string;
};

export const getFieldDataDetailListAction =
  (
    dataToBeSent: GetFieldDataDetailListTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getFieldDataDetailList(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(Actions.GET_FIELD_DATA_DETAIL_LIST, {
          fieldDatas: response?.data,
        })
      );
    return response;
  };

/* GET FIELD DATA*/
/* ADD FIELD DATA*/

export type AddFieldDataDetailTypes = {
  id: number;
  projectId: number;
  item: string;
  activity: string;
  quantity: string;
  unit: number;
  size: number;
  sedlevel: string;
  unitPrice: number;
  amount: number;
  structure: string;
  structureId: string;
  usmh: string;
  dsmh: string;
  structureSize: string;
  length: string;
  depth: string;
};

export const addFieldDataDetailAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddFieldDataDetailTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addFieldDataDetail(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(Actions.ADD_EDIT_FIELD_DATA_DETAILS, {
          fieldDatas: response?.data,
        })
      );
    if (response) {
      navigate(AppRoutingConfig.APP_URL_FIELD_DATA_MODULE_LIST);
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };
/* ADD FIELD DATA*/

/* GET FIELD DATA DETAIL*/
export const getFieldDataDetailAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    setLoading: Dispatch<SetStateAction<boolean>>,
    dataToBeSent: { FieldDataId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getFieldDataDetail(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(Actions.GET_FIELD_DATA_DETAILS, {
          fieldDataDetail: response?.data,
        })
      );
    return response;
  };
/* GET FIELD DATA DETAIL*/

/* CHANGE FIELD DATA STATUS */
export const changeFieldDataStatusAction =
  (
    dataToBeSent: { id: number; isActive: boolean }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.changeFieldDataStatus(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(Actions.UPDATE_FIELD_DATA_STATUS, {
          fieldDataStatus: response?.data,
        })
      );
    return response;
  };
/* CHANGE FIELD DATA STATUS */

/* GET PROJECT  */

export const getProjectByIdAction =
  (
    dataToBeSent: { ProjectId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getProjectById(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(Actions.GET_PROJECT_DETAILS_BY_ID, {
          ProjectId: response?.data,
        })
      );
    return response;
  };

/* GET PROJECT  */

// GET EMPLOYEE SELECT LIST

export const getEmployeeSelectListAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getEmployeeSelectList({})
    response &&
      dispatch(
        Actions.createAction(Actions.GET_EMPLOYEE_SELECT_LIST, {
          employees: response?.data,
        })
      );
    return response;
  };

// GET ACTIVITY STATUS SELECT LIST

export const getActivityStatusSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getActivityStatusSelectList()

    response && dispatch(Actions.createAction(Actions.GET_ACTIVITY_STATUS_SELECT_LIST, {activityStatusSelectList: response?.data}));
    return response;
  };

// GET VEHICLE SELECT LIST

export const getVehicleSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getVehicleSelectList()

    response && dispatch(Actions.createAction(Actions.GET_VEHICLE_SELECT_LIST, response?.data));
    return response;
  };
// GET FIELD DATA LIST
export const getFieldDataListAction =
  (
    dataToBeSent: {
      projectId: number;
      projectScheduleId: number;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getFieldDataList(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_FIELD_DATA_LIST, {fieldDataList:response?.data}));
    return response;
  };
