import { Navigate, createHashRouter } from "react-router-dom";
import RequireAuth from "../requireAuth/RequireAuth";
import DashboardConfig from "../../components/pages/modules/admin/dashboard/DashboardConfig";
import RegionConfig from "../../components/pages/modules/admin/region/RegionConfig";
import SignInConfig from "../../components/pages/auth/signIn/SignInConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import MainLayout from "../../components/layouts/mainLayout";
import AuthLayout from "../../components/layouts/authLayout";
import PageNotFound from "../../components/pages/pageNotFound/PageNotFound";
import DepartmentConfig from "../../components/pages/modules/admin/department/DepartmentConfig";
import ForgotPasswordConfig from "../../components/pages/auth/forgotPassword/ForgotPasswordConfig";
import HrConfig from "../../components/pages/modules/hr/HrConfig";
import SalesConfig from "../../components/pages/modules/sales/SalesConfig";
import AdminConfig from "../../components/pages/modules/admin/AdminConfig";
import OperationsConfig from "../../components/pages/modules/operations/OperationsConfig";
import CrmConfig from "../../components/pages/modules/crm/CrmConfig";
import FleetConfig from "../../components/pages/modules/fleet/FleetConfig";
import { AxiosInterceptor } from "../../axios/axiosInstance";
import FinanceConfig from '../../components/pages/modules/finance/FinanceConfig'

/* COMBINED MODULE CONFIGS */
const moduleConfigs = [
  ...HrConfig.routes,
  ...AdminConfig.routes,
  ...SalesConfig.routes,
  ...OperationsConfig.routes,
  ...CrmConfig.routes,
  ...FleetConfig.routes,
  ...FinanceConfig.routes
];
/* COMBINED MODULE CONFIGS */

export const RoutesConfig = createHashRouter([
  {
    path: "*",
    element: <Navigate to={AppRoutingConfig.APP_URL_AUTH_PAGE_NOT_FOUND} />,
  },
  {
    path: AppRoutingConfig.APP_URL_AUTH_PAGE_NOT_FOUND,
    element: <PageNotFound />,
  },
  {
    element: <AxiosInterceptor><AuthLayout /></AxiosInterceptor>,
    children: [...SignInConfig.routes, ...ForgotPasswordConfig.routes],
  },
  {
    element: <AxiosInterceptor><RequireAuth /></AxiosInterceptor>, 
    children: [{
        path: AppRoutingConfig.APP_URL_DEFAULT_HOME,
        element: <MainLayout />,
        children: moduleConfigs,
      }],
  },
]);
