import Actions from "../actions";
import { produce } from "immer";

interface State {
  financeDashboardList: {
    totalCount: number;
    financeDetails: [];
  };
}

const initialState: State = {
  financeDashboardList: {
    totalCount: 0,
    financeDetails: [],
  },
};

const finance = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_FINANCE_DASHBOARD_LIST: {
      draft.financeDashboardList = payload?.financeDashboardList;
      break;
    }

    case Actions.EMPTY_FINANCE_DASHBOARD_LIST: {
      draft.financeDashboardList = {
        totalCount: 0,
        financeDetails: [],
      };
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default finance;
