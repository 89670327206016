import axiosConfig from '../../../axios/axiosConfig'
import AppApiConfig from '../../../assets/config/AppApiConfig'
import axiosConfigInstance from '../../../axios/axiosInstance';

export const GET_PROJECT_SCHEDULING_LIST_URL =
  AppApiConfig.API_PROJECT_SCHEDULING_LIST_URL;
export const GET_FIELD_DATA_LIST_URL = AppApiConfig.API_FIELD_DATA_LIST_URL;
export const ADD_EDIT_FIELD_DATA_DETAIL_URL =
  AppApiConfig.API_SAVE_FIELD_DATA_MODULE_URL;
export const GET_FIELD_DATA_DETAIL_URL =
  AppApiConfig.API_LOAD_FIELD_DATA_DETAIL_URL;
export const ADD_EDIT_PROJECT_SCHEDULING_URL =
  AppApiConfig.API_ADD_EDIT_PROJECT_SCHEDULING_URL;
export const EDIT_PROJECT_SCHEDULING_STATUS_URL =
  AppApiConfig.API_EDIT_PROJECT_SCHEDULING_STATUS_URL;
export const ACTIVE_INACTIVE_PROJECT_SCHEDULE_URL =
  AppApiConfig.API_ACTIVE_INACTIVE_PROJECT_SCHEDULING_URL;

export const getProjectSchedulings = (dataToBeSent: {
  isActive: null;
  pageNumber: number;
  pageSize: number;
  orderBy: boolean;
  searchString: String;
}) => {
  return axiosConfigInstance.post(GET_PROJECT_SCHEDULING_LIST_URL, dataToBeSent);
};
export const addProjectSchedulings = (dataToBeSent: any) => {
  return axiosConfigInstance.post(ADD_EDIT_PROJECT_SCHEDULING_URL, dataToBeSent);
};
export const editProjectSchedulingStatus = (dataToBeSent: {
  projectScheduleId: number;
  status: string;
}) => {
  return axiosConfigInstance.post(EDIT_PROJECT_SCHEDULING_STATUS_URL, dataToBeSent);
};
export const getProjectSchedulingDetail = (dataToBeSent: {ProjectScheduleId: number}) => {
  return axiosConfigInstance.post(AppApiConfig.API_GET_PROJECT_SCHEDULING_DETAIL_URL,{},{ params: dataToBeSent });
};

export const activeInactiveProjectScheduling = (dataToBeSent: {
  projectScheduleId: number;
  isActive: boolean;
}) => {
  return axiosConfigInstance.post(ACTIVE_INACTIVE_PROJECT_SCHEDULE_URL, dataToBeSent);
};

export const getProjectById = (dataToBeSent: { ProjectId: string }) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_PROJECT_DETAILS_BY_ID_URL,
    {},
    { params: dataToBeSent }
  );
};

export const getEmployeeSelectList = (dataToBeSent: {}) => {
  return axiosConfigInstance.post(AppApiConfig.API_EMPLOYEE_SELECT_LIST, dataToBeSent);
};

export const getActivityStatusSelectList = () => {
  return axiosConfigInstance.post(AppApiConfig.API_ACTIVITY_STATUS_SELECT_LIST_URL);
};

export const getVehicleSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_VEHICLE_SELECT_LIST_URL
  );
};

export const getFieldDataList = (dataToBeSent: {
  projectId: number;
  projectScheduleId: number;
}) => {
  return axiosConfigInstance.post(AppApiConfig.API_FEILD_DATA_LIST_URL, dataToBeSent);
};

export const getFieldDatas = (dataToBeSent: {
  isActive: null;
  pageNumber: number;
  pageSize: number;
  orderBy: boolean;
  searchString: String;
}) => {
  return axiosConfigInstance.post(GET_FIELD_DATA_LIST_URL, dataToBeSent);
};

export const addFieldDataDetail = (dataToBeSent: {
  id: number;
  projectId: number;
  item: string;
  activity: string;
  quantity: String;
  unit: number;
  size: number;
  sedlevel: string;
  unitPrice: number;
  amount: number;
  structure: string;
  structureId: string;
  usmh: string;
  dsmh: string;
  structureSize: string;
  length: string;
  depth: string;
}) => {
  return axiosConfigInstance.post(ADD_EDIT_FIELD_DATA_DETAIL_URL, dataToBeSent);
};

export const getFieldDataDetail = (dataToBeSent:{FieldDataId: string}) => {
  return axiosConfigInstance.post(
    GET_FIELD_DATA_DETAIL_URL,{},{params:dataToBeSent}
  )
}
export const getFieldDataDetailList = (dataToBeSent:{isActive:null,pageNumber:number,pageSize:number,orderBy:boolean,searchString:String}) => {
  return axiosConfigInstance.post(
    GET_FIELD_DATA_LIST_URL,
    dataToBeSent
  )
}
export const changeFieldDataStatus = (dataToBeSent: {
  id: number;
  isActive: boolean;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CHANGE_FIELD_DATA_STATUS_URL,
    dataToBeSent
  );
};
