import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "./sideBar";
import Header from "./header";
import Card from "../../common/card";
import Tabs from "../../common/tabs";
import { appAdminMenuUtils } from "../../../utils/appAdminMenuUtils";
import { appOperationsMenuUtils } from "../../../utils/appOperationsMenuUtils";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import { appHrMenuUtils } from "../../../utils/appHrMenuUtils";
import "./MainLayout.scss";
import { useSalesMenuUtils } from "../../../utils/tabNavigationUtils/useSalesMenuUtils";
import { useCrmMenuUtils } from '../../../utils/tabNavigationUtils/useCrmMenuUtils';
import {appFleetMenuUtils} from '../../../utils/appFleetMenuUtils'
import { useFinanceMenuUtils } from "../../../utils/tabNavigationUtils/useFinanceMenuUtils";
import AnimatedSideBar from "./sideBar/AnimatedSideBar";

type Props = {};

const MainLayout = (props: Props) => {
  const location = useLocation();

  const [salesMenuGroup] = useSalesMenuUtils()
  const [crmMenuGroup] = useCrmMenuUtils()
  const [financeMenuGroup] = useFinanceMenuUtils()
  const listRoutesArray = [...appAdminMenuUtils(), ...appOperationsMenuUtils(), ...appHrMenuUtils(),...salesMenuGroup,...crmMenuGroup,...appFleetMenuUtils(),...financeMenuGroup]
  
  /* function definition to render the tabs based on module */
  const getTabsBasedOnModule = () => {
    const pathname = location.pathname;
    if (pathname.includes(AppRoutingConfig.APP_URL_ADMIN)) {
      return appAdminMenuUtils();
    } else if (pathname.includes(AppRoutingConfig.APP_URL_OPERATIONS)) {
      return appOperationsMenuUtils();
    } else if (pathname.includes(AppRoutingConfig.APP_URL_HR)){
      return appHrMenuUtils()
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_SALES)){
      return salesMenuGroup
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_CRM)){
      return crmMenuGroup
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_FLEET)) {
      return appFleetMenuUtils();
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_FINANCE)) {
      return financeMenuGroup
    }  
  };
  /* function definition to render the tabs based on module */


  return (
    <div className="main-layout-container">
      <AnimatedSideBar />
      <div className="header-content-wrapper">
        <Header />
        {
          listRoutesArray.some((element)=>element.url === location.pathname) ? 
          <Tabs tabsMenuUtils={getTabsBasedOnModule()} />
          :
          <Outlet/>
        }
      </div>
    </div>
  );
};

export default MainLayout;
