export const appHrMenuUtils = () => {
  const menuGroup = [];

  /* menuItemHrDashboard */
  const menuItemHrDashboard = {
    id: "hrDashboard",
    title: "Dashboard",
    url: "/hr/dashboard",
  };
  menuGroup.push(menuItemHrDashboard);
  /* menuItemHrDashboard */

  /* menuItemHrEmployeeManagement */
  const menuItemHrEmployeeManagement = {
    id: "employeeManagement",
    title: "Employee Management",
    url: "/hr/employeeManagement",
  };
  menuGroup.push(menuItemHrEmployeeManagement);
  /* menuItemHrEmployeeManagement */

  /* menuItemHrDocumentManagement */
  const menuItemHrDocumentManagement = {
    id: "documentManagement",
    title: "Document Management",
    url: "/hr/documentManagement",
  };
  menuGroup.push(menuItemHrDocumentManagement);
  /* menuItemHrDocumentManagement */

  /* menuItemHrAttendanceManagement  */
  const menuItemHrAttendanceManagement = {
    id: "attendanceManagement",
    title: "Attendance Management",
    url: "/hr/attendanceManagement",
  };
  menuGroup.push(menuItemHrAttendanceManagement);
  /* menuItemHrAttendanceManagement  */

  /* menuItemHrLeaveManagement */
  const menuItemHrLeaveManagement = {
    id: "leaveManagement",
    title: "Leave Management",
    url: "/hr/leaveManagement",
  };
  menuGroup.push(menuItemHrLeaveManagement);
  /* menuItemHrLeaveManagement */
  return menuGroup;
};
