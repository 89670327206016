import axiosConfig from "../../../axios/axiosConfig";
import AppApiConfig from "../../../assets/config/AppApiConfig";
import {
  GetCompanyDocumentsTypes,
  GetEmployeeDocumentsTypes,
} from "./documentManagementServices";
import axiosConfigInstance from "../../../axios/axiosInstance";

export const GET_EMPLOYEE_DOCUMENT_URL =
  AppApiConfig.API_EMPLOYEE_DOCUMENT_MANAGEMENT_MODULE_LIST_URL;
export const GET_COMPANY_DOCUMENT_URL =
  AppApiConfig.API_COMPANY_DOCUMENT_MANAGEMENT_MODULE_LIST_URL;
export const GET_DOCUMENT_TYPE_SELECT_LIST_URL =
  AppApiConfig.API_DOCUMENT_TYPE_SELECT_LIST_URL;

export const getEmployeeDocuments = (
  dataToBeSent: GetEmployeeDocumentsTypes
) => {
  return axiosConfigInstance.post(GET_EMPLOYEE_DOCUMENT_URL, dataToBeSent);
};

export const getEmployeeDocumentManagementById = (dataToBeSent: {
  UserDocumentId: number;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_EMPLOYEE_DOCUMENT_MANAGEMENT_MODULE_DETAIL_URL,
    {},
    { params: dataToBeSent }
  );
};

export const addEmployeeDocument = (dataToBeSent: FormData) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SAVE_EMPLOYEE_DOCUMENT_MANAGEMENT_URL,
    dataToBeSent
  );
};

export const getCompanyDocuments = (dataToBeSent: GetCompanyDocumentsTypes) => {
  return axiosConfigInstance.post(GET_COMPANY_DOCUMENT_URL, dataToBeSent);
};

export const getCompanyDocumentManagementById = (dataToBeSent: {
  CompanyDocumentId: number;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_COMPANY_DOCUMENT_MANAGEMENT_MODULE_DETAIL_URL,
    {},
    { params: dataToBeSent }
  );
};

export const addCompanyDocument = (dataToBeSent: FormData) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SAVE_COMPANY_DOCUMENT_MANAGEMENT_URL,
    dataToBeSent
  );
};

export const getDocumentTypeSelectList = (dataToBeSent: {
  ShowCompanyDocuments: boolean;
}) => {
  return axiosConfigInstance.post(
    GET_DOCUMENT_TYPE_SELECT_LIST_URL,
    {},
    { params: dataToBeSent }
  );
};
