import React, { useEffect, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../../common/button";
import TextArea from "../../../../common/textArea";
import InputField from "../../../../common/inputField";
import Select from "../../../../common/select";
import {
  getCitySelectListAction,
  getCountrySelectListAction,
  getStateSelectListAction,
} from "../../../../../redux/services/region/regionServices";
import {
  dateToDispViewIsoFormat,
  getFilteredObjectFromId,
  isoToDispViewDate,
  dateToTimestamp,
  isoToDispViewDateObj,
  dateObjToDispViewIsoFormat,
} from "../../../../../utils/appCommonUtils";
import Actions from "../../../../../redux/actions";
import {
  addProjectSummaryAction,
  getCustomerListAction,
  getProjectIdAction,
  getProjectSummaryDetailAction,
  getStatusListAction,
} from "../../../../../redux/services/projectSummary/projectSummaryServices";
import UserSessionService from "../../../../../services/UserSessionService";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import TableLoader from "../../../../common/tableLoader";
import { useToast } from "../../../../../hooks/useToast";
import "./ProjectSummary.scss";
import { allowCharactersOnlyForDecimalNumbers, allowCharactersOnlyForIntegerNumbers } from "../../../../../utils/appKeyStrokeLimitingUtils";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import Datepicker from "../../../../common/datepicker";
import { checkIfStringIsValidTwoDigitDecimal } from "../../../../../utils/appValidatorUtils";

type Props = {};

type SelectListProps = { value: any; label: string };

type DefaultValueTypes = {
  id: number;
  projectNo: string;
  projectName: string;
  serviceOrderNo: string;
  po: string;
  customer: null;
  addressLine1: string;
  addressLine2: string;
  country: null;
  state: null;
  city: null | any;
  zipCode: string;
  status: null;
  amount: string;
  startDate: Date | null;
  endDate: Date | null;
  typeOfWork: string;
  workDetails: string;
};

const ProjectSummaryForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  const [loadingComponent, setLoadingComponent] = useState(false);
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const location = useLocation();
  const navigate = useNavigate();
  const params = location?.state;
  const { showToast } = useToast();
  /* For navigating between pages */

  /*Temporary module dependencies - Edit */
  const [temProjectSummaryId, setTempProjectSummaryId] = useState();
  const [tempProjectSummaryDetails, setTempProjectSummaryDetails] = useState();
  /*Temporary module dependencies - Edit */

  /* searchable select dependencies */
  const { countrySelectList, stateSelectList, citySelectList } = useAppSelector(
    (state) => state.region
  );

  const { customerSelectList, statusSelectList } = useAppSelector(
    (state) => state.projectSummary
  );
  /* searchable select dependencies */

  /* Dependencies for default values and validation */

  const defaultValues: DefaultValueTypes = {
    id: 0,
    projectNo: "",
    projectName: "",
    serviceOrderNo: "",
    po: "",
    customer: null,
    addressLine1: "",
    addressLine2: "",
    country: null,
    state: null,
    city: null,
    zipCode: "",
    status: null,
    amount: "",
    startDate: null,
    endDate: null,
    typeOfWork: "",
    workDetails: "",
  };

  const schema = yup.object().shape({
    projectNo: yup
      .string()
      .max(20)
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.ProjectNoIsRequired")
      ),
    projectName: yup
      .string()
      .max(100)
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.ProjectNameIsRequired")
      ),
    serviceOrderNo: yup
      .string()
      .max(20)
      .required(
        t(
          "Operations.ProjectSummary.ProjectSummaryForm.ServiceOrderNoIsRequired"
        )
      ),
    po: yup
      .string()
      .max(20)
      .required(t("Operations.ProjectSummary.ProjectSummaryForm.POIsRequired")),
    customer: yup
      .object()
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.CustomerIsRequired")
      ),
    addressLine1: yup
      .string()
      .max(500)
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.AddressLine1IsRequired")
      ),
      addressLine2: yup
      .string()
      .max(500),
    country: yup
      .object()
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.CountryIsRequired")
      ),
    state: yup
      .object()
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.StateIsRequired")
      ),
    zipCode: yup
      .string()
      .max(10)
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.ZipCodeIsRequired")
      ),
    status: yup
      .object()
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.StatusIsRequired")
      ),
      amount: yup.string().max(10).test(
        t("Operations.ProjectSummary.ProjectSummaryForm.Amount"),
        t("CommonUtils.Validations.InvalidNumberRequiredPositiveDecimalUptoTwoPlaces"),
        (value) => {
            return checkIfStringIsValidTwoDigitDecimal(value);
        }
    ),
    startDate: yup.date().required(t("Operations.ProjectSummary.ProjectSummaryForm.StartDateIsRequired"))
      .test(
        "START_DATE_VALIDATION_CHECK",
        t("Operations.ProjectSummary.ProjectSummaryForm.StartDateValidation"),
        function (value) {
          const { endDate } = this.parent;
          if (dateToTimestamp(isoToDispViewDate(new Date(value), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT)) <= 
          dateToTimestamp(isoToDispViewDate(new Date(endDate), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT))) {
            return true;
          } else {
            return false;
          }
        }
      ),
    endDate: yup.date().required(t("Operations.ProjectSummary.ProjectSummaryForm.EndDateIsRequired"))
      .test(
        "END_DATE_VALIDATION_CHECK",
        t("Operations.ProjectSummary.ProjectSummaryForm.EndDateValidation"),
        function (value) {
          const { startDate } = this.parent;
          if (dateToTimestamp(isoToDispViewDate(new Date(value), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT)) >= 
          dateToTimestamp(isoToDispViewDate(new Date(startDate), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT))) {
            return true;
          } else {
            return false;
          }
        }
      ),
    typeOfWork: yup
      .string()
      .max(500)
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.TypeofWorkIsRequired")
      ),
  });

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    watch,
    reset,
    trigger,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors, dirtyFields } = formState;

  const { startDate, projectNo, projectName, customer } = watch();
  /* Dependencies for default values and validation */

  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
    // Country select list
    dispatch(getCountrySelectListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.country, apiRes?.data).then(
            (country: any) => {
              country && loadDispatchStateForCountry(country);
              setValue("country", country);
            }
          );
      }
    );

    // State select list based on country
    const loadDispatchStateForCountry = (country: {
      value: any;
      label: string;
    }) => {
      dispatch(getStateSelectListAction({CountryId: country?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.state, apiRes?.data).then(
            (state: any) => {
              state && loadDispatchCityForState(state);
              setValue("state", state);
            }
          );
      });
    };

    // City select list based on state
    const loadDispatchCityForState = (state: { value: any; label: string }) => {
      dispatch(getCitySelectListAction({StateId: state?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.city, apiRes?.data).then(
            (city: any) => {
              setValue("city", city);
            }
          );
      });
    };

    // Customer select list
    dispatch(
      getCustomerListAction({ RegionId: "" })
    ).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.customerId, apiRes?.data).then(
          (customer: any) => {
            setValue("customer", customer);
          }
        );
    });

    // status select list
    dispatch(getStatusListAction()).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.status, apiRes?.data).then(
          (status: any) => {
            setValue("status", status);
          }
        );
    });
  };

  /* function definition for select list API calls */

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);

      //Generated Project ID
      dispatch(
        getProjectIdAction()
      ).then((apiRes) => {
        setValue("projectNo", apiRes?.data);
      });
    }
  }, []);
  /* load initial dependencies - Add */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { projectSummaryId } = params;
      setTempProjectSummaryId(projectSummaryId);
      setLoadingComponent(true)
      dispatch(getProjectSummaryDetailAction({projectId: projectSummaryId})
      ).then((apiRes) => {
        const projectSummaryDetail = apiRes?.data;
        if (projectSummaryDetail) {
          selectListApiCalls(projectSummaryDetail);
          setTempProjectSummaryDetails(projectSummaryDetail);

          const {customer,country,state,city,startDate,endDate,...rest} = projectSummaryDetail;
          reset({
              ...rest,
              id: projectSummaryId,
              status: projectSummaryDetail?.status,
              startDate: isoToDispViewDateObj(projectSummaryDetail?.startDate),
              endDate: isoToDispViewDateObj(projectSummaryDetail?.endDate),
            },
            { keepDirtyValues: true }
          )
          setLoadingComponent(false)
        }
      });
    }
  }, []);
  /* load initial dependencies - Edit */

  /* On Country changed function */
  const onCountryChanged = (
    option: SelectListProps,
    isClearAction: boolean
  ) => {
    if (option && option !== null && option !== undefined) {
      loadStateForCountry(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CITY_SELECT_LIST));
      dispatch(Actions.createAction(Actions.RESET_STATE_SELECT_LIST));
    }
    setValue("state", null);
    setValue("city", null);
  };

  /* for loading region code based on country */
  const loadStateForCountry = (option: SelectListProps) => {
    const dataToBeSent = {
      CountryId: option.value,
    };
    dispatch(getStateSelectListAction(dataToBeSent));
  };
  /* On Country changed function */

  /* On State changed function */
  const onStateChanged = (option: SelectListProps, isClearAction: boolean) => {
    if (option && option !== null && option !== undefined) {
      loadCityForState(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CITY_SELECT_LIST));
    }
    setValue("city", null);
  };
  /* On State changed function */

  /* for loading city code based on state */
  const loadCityForState = (option: SelectListProps) => {
    const dataToBeSent = {
      StateId: option.value,
    };
    dispatch(getCitySelectListAction(dataToBeSent));
  };
  /* for loading city code based on state */

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    const {
      customer,
      country,
      state,
      city,
      status,
      startDate,
      endDate,
      amount,
      ...rest
    } = formData;

    const dataToBeSent = {
      ...rest,
      loginUserId: UserSessionService.getLoginUserId(),
      customerId: customer && customer?.value,
      country: country && country?.value,
      state: state && state?.value,
      city: city ? city?.value : 0 ,
      status: status && status?.value,
      amount: amount ? amount : 0,
      startDate: dateObjToDispViewIsoFormat(startDate),
      endDate: dateObjToDispViewIsoFormat(endDate),
    };

    dispatch(addProjectSummaryAction(navigate,showToast,setLoadingComponent,dataToBeSent,location));
  };
  /* Form submit handler */

  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <Card>
      <div className="project-summary-form-container flex_box flex_box--column">
        <Grid container>
          <Title>
            {t("Operations.ProjectSummary.ProjectSummaryForm.AddProjects")}
          </Title>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit)}
          name="projectSummaryForm"
          id="projectSummaryForm"
        >
          <Grid container spacing="sm">
            {/* PROJECT NO */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.ProjectNo")}
              </Label>
              <InputField
                name="projectNo"
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterProjectNo"
                )}
                disabled
              />
            </Grid>
            {/* PROJECT NO */}

            {/* PROJECT NAME */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.ProjectName")}
              </Label>

              <InputField
                name="projectName"
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterProjectName"
                )}
                disabled={
                  !params || params === null || params === undefined
                    ? false
                    : true
                }
              />
            </Grid>
            {/* PROJECT NAME */}

            {/* SERVICE ORDER NO */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t(
                  "Operations.ProjectSummary.ProjectSummaryForm.ServiceOrderNo"
                )}
              </Label>
              <InputField
                name="serviceOrderNo"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterServiceOrderNo"
                )}
              />
            </Grid>
            {/* SERVICE ORDER NO */}

            {/* PO */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.Po")}
              </Label>

              <InputField
                name="po"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterPo"
                )}
              />
            </Grid>
            {/* PO */}

            {/* CUSTOMER */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.Customer")}
              </Label>
              <Select
                name="customer"
                options={customerSelectList}
                control={control}
                isClearable
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.SelectCustomer"
                )}
                isDisabled={
                  !params || params === null || params === undefined
                    ? false
                    : true
                }
                isLoading={apiLoader}
              />
            </Grid>
            {/* CUSTOMER */}

            {/* ADDRESS LINE 1 */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.AddressLine1")}
              </Label>

              <InputField
                name="addressLine1"
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterAddressLine1"
                )}
              />
            </Grid>
            {/* ADDRESS LINE 1 */}

            {/* ADDRESS LINE 2 */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>
                {t("Operations.ProjectSummary.ProjectSummaryForm.AddressLine2")}
              </Label>

              <InputField
                name="addressLine2"
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterAddressLine2"
                )}
              />
            </Grid>
            {/* ADDRESS LINE 2 */}

            {/* COUNTRY */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.Country")}
              </Label>
              <Select
                name="country"
                options={countrySelectList}
                control={control}
                isClearable
                onCustomChange={(option, isClearAction) => {
                  onCountryChanged(option, isClearAction);
                }}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.SelectCountry"
                )}
                isLoading={apiLoader}
              />
            </Grid>
            {/* COUNTRY */}

            {/* STATE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.State")}
              </Label>

              <Select
                name="state"
                options={stateSelectList}
                control={control}
                onCustomChange={(option, isClearAction) => {
                  onStateChanged(option, isClearAction);
                }}
                isClearable
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.SelectState"
                )}
                isLoading={apiLoader}
              />
            </Grid>
            {/* STATE */}

            {/* CITY */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>
                {t("Operations.ProjectSummary.ProjectSummaryForm.City")}
              </Label>

              <Select
                name="city"
                options={citySelectList}
                control={control}
                isClearable
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.SelectCity"
                )}
                isLoading={apiLoader}
              />
            </Grid>
            {/* CITY */}

            {/* ZIP CODE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.ZipCode")}
              </Label>
              <InputField
                name="zipCode"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterZipCode"
                )}
              />
            </Grid>
            {/* ZIP CODE */}

            {/* STATUS */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.Status")}
              </Label>
              <Select
                name="status"
                options={statusSelectList}
                control={control}
                isClearable
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.SelectStatus"
                )}
                isLoading={apiLoader}
              />
            </Grid>
            {/* STATUS */}

            {/* AMOUNT */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>
                {t("Operations.ProjectSummary.ProjectSummaryForm.Amount")} ($)
              </Label>
              <InputField
                name="amount"
                onKeyDown={(e)=>allowCharactersOnlyForDecimalNumbers(e)}
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterAmount"
                )}
              />
            </Grid>
            {/* AMOUNT */}

            {/* START DATE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.StartDate")}
              </Label>
              <Datepicker name="startDate" onCustomChange={async () => await trigger("endDate")} control={control} isClearable/>
            </Grid>
            {/* START DATE */}

            {/* END DATE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.EndDate")}
              </Label>
              <Datepicker name="endDate" minDate={startDate} onCustomChange={async () => await trigger("startDate")} control={control} isClearable/>
            </Grid>
            {/* END DATE */}

            {/* TYPE OF WORK */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.ProjectSummary.ProjectSummaryForm.TypeofWork")}
              </Label>
              <InputField
                name="typeOfWork"
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterTypeofWork"
                )}
              />
            </Grid>
            {/* TYPE OF WORK */}

            {/* WORK DETAILS */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Label>
                {t("Operations.ProjectSummary.ProjectSummaryForm.WorkDetails")}
              </Label>
              <TextArea
                rows={4}
                name="workDetails"
                control={control}
                placeholder={t(
                  "Operations.ProjectSummary.ProjectSummaryForm.EnterWorkDetails"
                )}
              />
            </Grid>

            {/* Buttons */}
            <Grid item xs={12} textAlign="center">
              <Button type="submit" form="projectSummaryForm">
                {t("CommonUtils.Button.Submit")}
              </Button>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  {
                    !params || params === null || params === undefined
                      ? reset({ ...defaultValues, projectNo: projectNo })
                      : reset({
                          ...defaultValues,
                          projectNo: projectNo,
                          customer: customer,
                          projectName: projectName,
                        });
                  }
                }}
              >
                {t("CommonUtils.Button.Reset")}
              </Button>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  if(location.pathname.includes("sales")){
                    navigate(AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_LIST);
                  }
                  else {
                    navigate(AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST);
                  }
                }}
              >
                {t("CommonUtils.Button.Back")}
              </Button>
            </Grid>
            {/* Buttons */}
          </Grid>
        </form>
      </div>
    </Card>
  );
};

export default ProjectSummaryForm;
