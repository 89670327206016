import AppConfig from "../assets/config/AppConfig";

type UserSessionServiceProps = {
  token: string;
  userName: string;
  userLastName: string;
  userEmail: string;
  userMobileNo: string;
  userCode: string;
  loginUserId: number;
};

class UserSessionService {
  /* Function definition to set the user session */
  setUserSession({
    token,
    userName,
    userLastName,
    userEmail,
    userMobileNo,
    userCode,
    loginUserId,
  }: UserSessionServiceProps) {
    const userNameKey = AppConfig.SESSION_USER_NAME;
    const userlastNameKey = AppConfig.SESSION_USER_LAST_NAME;
    const userEmailKey = AppConfig.SESSION_USER_EMAIL;
    const userMobileNoKey = AppConfig.SESSION_USER_MOBILE_NO;
    const userCodeKey = AppConfig.SESSION_USER_CODE;
    const loginUserIdKey = AppConfig.SESSION_USER_ID;

    const encodedKey = encodeURIComponent(token);

    const userObj = {
      [userNameKey]: userName,
      [userlastNameKey]: userLastName,
      [userEmailKey]: userEmail,
      [userMobileNoKey]: userMobileNo,
      [userCodeKey]: userCode,
      [loginUserIdKey]: loginUserId,
    };

    localStorage.setItem(AppConfig.SESSION_USER_KEY, encodedKey);
    localStorage.setItem(AppConfig.SESSION_USER_INFO, JSON.stringify(userObj));
    localStorage.setItem(AppConfig.SESSION_IS_USER_LOGGED_IN, "1");

    return;
  }
  /* Function definition to set the user session */

  /* Function definition to get the user session */
  getUserSessionObj() {
    let userDetail = null;
    let isLoggedIn = this.getUserLoggedInStatus();
    if (isLoggedIn) {
      const userDetailObj = localStorage.getItem(AppConfig.SESSION_USER_INFO);
      if (userDetailObj && userDetailObj !== "") {
        userDetail = JSON.parse(userDetailObj);
      }
    }
    return userDetail;
  }
  /* Function definition to get the user session */

  /* Function definition to get the user name */
  getUserSessionName() {
    let userName = ""
    let userObj = this.getUserSessionObj();
    let isLoggedIn = this.getUserLoggedInStatus();
    if (isLoggedIn && userObj && userObj !== null) {
      userName = userObj.userName + " " + userObj.userLastName;
    }
    return userName;
  }
  /* Function definition to get the user name */

  /* Function definition to get the user name */
  getUserSessionEmail() {
    let userEmail = ""
    let userObj = this.getUserSessionObj();
    let isLoggedIn = this.getUserLoggedInStatus();
    if (isLoggedIn && userObj && userObj !== null) {
      userEmail = userObj.userEmail;
    }
    return userEmail;
  }
  /* Function definition to get the user name */

  /* Function definition to get the user name */
  getUserSessionCode() {
    let userCode = ""
    let userObj = this.getUserSessionObj();
    let isLoggedIn = this.getUserLoggedInStatus();
    if (isLoggedIn && userObj && userObj !== null) {
      userCode = userObj.userCode;
    }
    return userCode;
  }
  /* Function definition to get the user name */

  /* Function definition to get the loginUserId */
  getLoginUserId(){
    let userId;
    let userObj = this.getUserSessionObj()

    if(userObj && userObj!==null && userObj!==undefined){
      userId = userObj.userId
    }

    return userId
  }
  /* Function definition to get the loginUserId */

  /* Function definition to get the user logged in status */
  getUserLoggedInStatus() {
    let isLoggedIn = false;
    const isUsrLoggedIn = localStorage.getItem(
      AppConfig.SESSION_IS_USER_LOGGED_IN
    );
    if (isUsrLoggedIn !== null && isUsrLoggedIn === "1") {
      isLoggedIn = true;
    }
    return isLoggedIn;
  }
  /* Function definition to get the user logged in status */

  /* Function definition to get the token from localstorage */
  getUserSessionToken() {
    let token = "";
    const userKeyObj = localStorage.getItem(AppConfig.SESSION_USER_KEY);
    if (userKeyObj !== null && userKeyObj !== "") {
      token = userKeyObj;
    }
    return token;
  }
  /* Function definition to get the token from localstorage */

  /* Function definition to remove user session */
  removeUserSession(){
    localStorage.clear()
  }
  /* Function definition to remove user session */
}

export default new UserSessionService();
