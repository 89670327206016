import React, { useEffect, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../common/button";
import InputField from "../../../../common/inputField";
import Select from "../../../../common/select";
import { getProjectListAction } from "../../../../../redux/services/projectSummary/projectSummaryServices";
import {
  getFilteredObjectFromId,
} from "../../../../../utils/appCommonUtils";
import {
  addFieldDataDetailAction,
  getFieldDataDetailAction,
} from "../../../../../redux/services/workflow/workFlowServices";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import TableLoader from "../../../../common/tableLoader";
import { useToast } from "../../../../../hooks/useToast";
import "./WorkFlow.scss";
import { allowCharactersOnlyForIntegerNumbers } from "../../../../../utils/appKeyStrokeLimitingUtils";

type Props = {};

type DefaultValueTypes = {
  id: number;
  projectId: null;
  item: string;
  activity: string;
  quantity: string;
  unit: string;
  size: string;
  sedlevel: string;
  unitPrice: string;
  amount: string;
  structure: string;
  structureId: string;
  usmh: string;
  dsmh: string;
  structureSize: string;
  length: string;
  depth: string;
};

const FieldDataForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  
  const [loadingComponent, setLoadingComponent] = useState(false)
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)


  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  const { showToast } = useToast();
  /* For navigating between pages */

  /*Temporary module dependencies - Edit */
  const [temPFieldDataId, setTempFieldDataId] = useState();
  const [tempFieldDataDetails, setTempFieldDataDetails] = useState();
  /*Temporary module dependencies - Edit */

  /* searchable select dependencies */
  const {projectSelect} = useAppSelector((state) => state.workFlow);
  /* searchable select dependencies */

  /* Dependencies for default values and validation */

  const defaultValues: DefaultValueTypes = {
    id: 0,
    projectId: null,
    item: "",
    activity: "",
    quantity: "",
    unit: "",
    size: "",
    sedlevel: "",
    unitPrice: "",
    amount: "",
    structure: "",
    structureId: "",
    usmh: "",
    dsmh: "",
    structureSize: "",
    length: "",
    depth: "",
  };

  const schema = yup.object().shape({
    projectId: yup
      .object()
      .required(t("Operations.WorkFlow.FieldDataForm.ProjectIsRequired")),
    item: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.ItemIsRequired")),
    activity: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.ActivityIsRequired")),
    quantity: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.QtyIsRequired")),
    unit: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.UnitIsRequired")),
    size: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.SizeIsRequired")),
    unitPrice: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.UnitPriceIsRequired")),
    amount: yup
      .string()
      .required(t("Operations.WorkFlow.FieldDataForm.AmountIsRequired")),
  });

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors, dirtyFields } = formState;
  /* Dependencies for default values and validation */
const {projectId} =watch()
  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
    let project;

    // Project select list
    dispatch(getProjectListAction({CustomerId: ""})).then((apiRes) => {
      if (details) {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.projectId, apiRes?.data).then(
            (project: any) => {
              setValue("projectId", project);
            }
          );
      }
    });
  };
  /* function definition for select list API calls */

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);
    }
  }, []);
  /* load initial dependencies - Add */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { fieldDataId } = params;
      setTempFieldDataId(fieldDataId);
      setLoadingComponent(true)
      const dataToBeSent = { FieldDataId: fieldDataId };
      dispatch(
        getFieldDataDetailAction(navigate, showToast, setLoadingComponent, dataToBeSent)
      ).then((apiRes) => {
        const fieldDataDetail  = apiRes?.data;
        if (fieldDataDetail) {
          selectListApiCalls(fieldDataDetail);
          setTempFieldDataDetails(fieldDataDetail);

          const { projectId, ...rest } = fieldDataDetail;

          reset({
            ...rest,
            id: fieldDataId,
            projectId: projectId && projectId?.value,
          });
          setLoadingComponent(false)
        }
      });
    }
  }, []);
  /* load initial dependencies - Edit */

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    const { projectId, ...rest } = formData;
    const dataToBeSent = {
      ...rest,
      projectId: projectId && projectId?.value,
    };

    dispatch(addFieldDataDetailAction(navigate,showToast, dataToBeSent))
  };

  /* Form submit handler */

  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <Card>
      <div className="workflow-form-container flex_box flex_box--column">
        <Grid container>
          <Title> {t("Operations.WorkFlow.FieldDataForm.AddFieldData")}</Title>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit)}
          name="fieldDataForm"
          id="fieldDataForm"
        >
          <Grid container spacing="sm">
            {/* PROJECT ID */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.WorkFlow.FieldDataForm.Project")}
              </Label>

              <Select
                name="projectId"
                options={projectSelect}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Operations.WorkFlow.FieldDataForm.SelectProject")}
                isDisabled={
                  !params || params === null || params === undefined
                    ? false
                    : true
                }
              />
            </Grid>
            {/* PROJECT ID */}
            {/* ITEM */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Operations.WorkFlow.FieldDataForm.Item")}</Label>
              <InputField
                name="item"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterItem")}
              />
            </Grid>
            {/* ITEM */}
            {/* ACTIVITY */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.WorkFlow.FieldDataForm.Activity")}
              </Label>

              <InputField
                name="activity"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterActivity")}
              />
            </Grid>
            {/* ACTIVITY */}
            {/* QUANTITY */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Operations.WorkFlow.FieldDataForm.QTY")}</Label>
              <InputField
                name="quantity"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterQty")}
              />
            </Grid>
            {/* QUANTITY */}
            {/* UNIT */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Operations.WorkFlow.FieldDataForm.Unit")}</Label>
              <InputField
                name="unit"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterUnit")}
              />
            </Grid>
            {/* UNIT */}
            {/* SIZE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Operations.WorkFlow.FieldDataForm.Size")}</Label>
              <InputField
                name="size"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterSize")}
              />
            </Grid>
            {/* SIZE */}
            {/* SED LEVEL */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.SEDLevel")}</Label>
              <InputField
                name="sedlevel"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterSEDLevel")}
              />
            </Grid>
            {/* SED LEVEL */}
            {/* UNIT PRICE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.WorkFlow.FieldDataForm.UnitPrice")}
              </Label>
              <InputField
                name="unitPrice"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterUnitPrice")}
              />
            </Grid>
            {/* UNIT PRICE */}
            {/* AMOUNT */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.WorkFlow.FieldDataForm.Amount")}
              </Label>
              <InputField
                name="amount"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterAmount")}
              />
            </Grid>
            {/* AMOUNT */}
            {/* STRUCTURE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.Structure")}</Label>
              <InputField
                name="structure"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterStructure")}
              />
            </Grid>
            {/* STRUCTURE */}
            {/* STRUCTURE ID */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.StructureID")}</Label>
              <InputField
                name="structureId"
                onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                control={control}
                placeholder={t(
                  "Operations.WorkFlow.FieldDataForm.EnterStructureID"
                )}
              />
            </Grid>
            {/* STRUCTURE ID */}
            {/* USMH */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.USMH")}</Label>
              <InputField
                name="usmh"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterUSMH")}
              />
            </Grid>
            {/* USMH */}
            {/* DSMH */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.DSMH")}</Label>
              <InputField
                name="dsmh"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterDSMH")}
              />
            </Grid>
            {/* DSMH */}
            {/* STRUCTURE SIZE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.StructureSize")}</Label>
              <InputField
                name="structureSize"
                control={control}
                placeholder={t(
                  "Operations.WorkFlow.FieldDataForm.EnterStructureSize"
                )}
              />
            </Grid>
            {/* STRUCTURE SIZE */}
            {/* LENGTH */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.Length")}</Label>
              <InputField
                name="length"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterLength")}
              />
            </Grid>
            {/* LENGTH */}
            {/* DEPTH */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Operations.WorkFlow.FieldDataForm.DEPTH")}</Label>
              <InputField
                name="depth"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterDEPTH")}
              />
            </Grid>
            {/* DEPTH */}
            <Grid item xs={12} textAlign="center">
              <Button type="submit" form="fieldDataForm">
                {t("CommonUtils.Button.Submit")}
              </Button>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  params && params?.fieldDataId !== null && params?.fieldDataId !== undefined? reset( {...defaultValues,projectId:projectId}):reset({...defaultValues})
                }}
              >
                {t("CommonUtils.Button.Reset")}
              </Button>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  navigate(AppRoutingConfig.APP_URL_FIELD_DATA_MODULE_LIST);
                }}
              >
                {t("CommonUtils.Button.Back")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Card>
  );
};

export default FieldDataForm;
