import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const Dashboard = lazy(()=> import("./Dashboard"))

const DashboardConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_SALES_DASHBOARD} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_DASHBOARD,
      element: <Dashboard />,
      handle: { crumb: () => "Dashboard" },
    },  
  ],
};

export default DashboardConfig